* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --trial-bar-height: 44px;
}

body {
  background-color: #f3f1e6;
  font-family: 'Poppins', sans-serif;
  color: #4a3a1f;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  justify-content: space-between;
}


.app-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  width: 100%;
  font-size: 16px;
  padding-top: 0;
  transition: padding-top 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.app-container.trial-mode {
  padding-top: var(--trial-bar-height);
}

@media (max-width: 768px) {
  :root {
    --trial-bar-height: 44px;
  }
}

@media (max-width: 480px) {
  :root {
    --trial-bar-height: 40px;
  }
}

.top-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 30px;
  background-color: transparent;
  position: absolute;
  top: 0;
  margin-top: 1%;
  width: 100%;
  z-index: 1000;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.trial-mode .top-bar {
  top: var(--trial-bar-height);
  margin-top: 0;
}

.nav-buttons {
  display: flex;
  gap: 20px;
  align-items: center;
}

.nav-buttons a {
  color: #5a4a2f;
  text-decoration: none;
  font-weight: 600;
  transition: all 0.3s ease;
  padding: 10px;
}

.nav-buttons a:hover {
  color: #ffaa00;
}

.company_logo {
  width: 85%; 
  height: auto;
  position: relative;
  z-index: 10;
  margin-bottom: 40px;
  display: block;
  margin: 0 auto;
}


.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-top: 60px;
}

.content-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border-radius: 12px;
  padding: 40px;
  width: 100%;
}

h1 {
  font-size: 4rem;
  color: #4a3a1f;
  margin-bottom: 20px;
  z-index: 10;
}

p {
  font-size: 1.4rem;
  color: #5a4a2f;
  margin-bottom: 50px;
  text-align: center;
  z-index: 10;
}


.search-bar-container {
  position: relative;
  width: 100%;
  margin-top: 5%;
  z-index: 10;
  align-items: center
}


.search-bar {
  width: 100%;
  font-size: 1.4rem;
  padding: 20px 60px 20px 25px;
  border: none;
  border-radius: 50px;
  background-color: #f0f0f0;
  transition: all 0.3s ease;
  box-shadow: 0 3px 15px rgba(0, 0, 0, 0.15);
  text-align: left;
}

.search-bar:focus {
  outline: none;
  box-shadow: 0 0 0 3px #ffcc00, 0 6px 12px rgba(0, 0, 0, 0.1);
}


.suggestions-container {
  width: 100%;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  margin-top: 10px;
  max-height: 300px;
  overflow-y: auto;
  z-index: 20;
  transition: all 0.3s ease;
  font-style: italic;
  position: absolute;
  margin-top: 5px;
  z-index: 1000;
}

.suggestion-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 16px;
  cursor: pointer;
  transition: all 0.2s ease;
  border-bottom: 1px solid #f5f5f5;
}

.suggestion-item:last-child {
  border-bottom: none;
}

.suggestion-main {
  display: flex;
  align-items: center;
  gap: 8px;
}

.suggestion-name {
  font-weight: 500;
  color: #333;
}

.suggestion-popularity {
  font-size: 0.8em;
  color: #666;
  background: #f0f0f0;
  padding: 2px 6px;
  border-radius: 12px;
}

.suggestion-type {
  font-size: 0.8em;
  color: #666;
  font-style: italic;
}

.suggestion-item:hover {
  background-color: rgba(255, 170, 0, 0.1);
}

.suggestion-item.loading {
  justify-content: center;
  color: #666;
  font-style: italic;
}

.suggestion-type {
  font-size: 0.8em;
  color: #666;
  margin-left: 8px;
}

.suggestion-item:active {
  background-color: #ffaa00;
  color: #fff;
}

.search-button {
  margin-top: 5%;
  font-size: 1.6rem;
  padding: 20px 30px;
  background-color: #e53540;
  border: none;
  border-radius: 40px;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  justify-content: center;
  align-items: center;
  color: white;
}

.inspire-button {
  margin-top: 5%;
  font-size: 1.6rem;
  padding: 20px 30px;
  background-color: #ffaa00;
  border: none;
  border-radius: 40px;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  justify-content: center;
  align-items: center;
  color: white;
}

.inspire-button:hover {
  background-color: #ff9900;
}

.search-button:hover {
  background-color: #ff6f61;
}

.suggestions-container + .search-button {
  margin-bottom: 20%; 
}

.suggestion-loader {
  width: 20px;
  height: 20px;
  border: 2px solid #f3f3f3;
  border-top: 2px solid #ffaa00;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  margin-right: 10px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.suggestion-item.loading,
.suggestion-item.no-results {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
  color: #666;
  font-style: italic;
}

@media (max-width: 768px) {
  .search-bar {
    font-size: 1.2rem;
    padding: 15px 50px 15px 20px;
  }
  
  .suggestion-item {
    font-size: 1rem;
  }

  .top-bar {
    padding: 10px 15px;
  }
}

.footer {
  background-color: transparent;
  padding: 20px;
  text-align: center;
  font-size: 0.9rem;
}

.button {
  font-size: 1.5rem;
  padding: 12px 25px;
  background-color: #ffaa00;
  border: none;
  border-radius: 40px;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 15px;
  color: white;
}

.button:hover {
  background-color: #ff9900;
  color: white;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}


.content-container::before {
  display: none;
}

.search-bottom-buttons {
  display: flex;
  gap: 3%;
  align-items: center;
  justify-content: center;
}

.overview-section,
.future-section {
  border-radius: 12px;
  padding: 2rem;
  margin-bottom: 4rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.section-content {
  margin-top: 1.5rem;
}

.section-description {
  font-size: 1.1rem;
  color: #333;
  line-height: 1.6;
  margin-bottom: 2rem;
}

.feature-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1.5rem;
}

.feature-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  padding: 1.5rem;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.feature-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.feature-icon {
  font-size: 2rem;
  margin-bottom: 1rem;
  color: #F9A826;
}

.feature-title {
  font-size: 1.2rem;
  font-weight: 600;
  color: #333;
  margin-bottom: 0.5rem;
}

.feature-description {
  font-size: 0.9rem;
  color: #666;
}

.search-mode-slider {
  display: flex;
  background: white;
  padding: 0.1rem;
  border-radius: 50px;
  gap: 0.1rem;
  box-shadow: 0 3px 15px rgba(0, 0, 0, 0.15);
  margin: 20px 0;
  width: 100%;
  position: relative;
  overflow: hidden;
}

.mode-button {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  padding: 12px 20px;
  border: none;
  border-radius: 40px;
  background: transparent;
  color: #4a3a1f;
  cursor: pointer;
  transition: color 0.3s ease;
  font-family: 'Poppins', sans-serif;
  font-size: 1rem;
  position: relative;
  z-index: 1;
}

.mode-button.active {
  color: white;
}

.mode-slider-background {
  position: absolute;
  top: 0.5rem;
  left: 0;
  width: calc(34.1% - 1rem);
  height: calc(100% - 1rem);
  background-color: #ffaa00;
  border-radius: 40px;
  transition: transform 0.5s cubic-bezier(0.65, 0, 0.35, 1);
  z-index: 0;
  box-shadow: 0 2px 8px rgba(255, 170, 0, 0.3);
}

.mode-button:nth-child(1).active ~ .mode-slider-background {
  transform: translateX(0.5rem);
}

.mode-button:nth-child(2).active ~ .mode-slider-background {
  transform: translateX(calc(100% + 1rem));
}

.mode-button:nth-child(3).active ~ .mode-slider-background {
  transform: translateX(calc(200% + 1.5rem));
}

.mode-icon {
  width: 20px;
  height: 20px;
}

.mode-label {
  font-weight: 500;
}

@media (max-width: 1000px){
  .inspire-button{
    font-size: 1.2rem;
    padding: 15px 20px;
  }
  .search-button{
    font-size: 1.2rem;
    padding: 15px 25px;
  }

  .search-bar {
    font-size: 0.9rem;
    padding: 12px 40px 12px 15px;
  }
  .mode-label {
    font-size: 0.9rem;
  }
  
  .mode-button {
    padding: 10px 15px;
  }
}


@media (max-width: 800px){
  .inspire-button{
    font-size: 1rem;
    padding: 15px 20px;
  }
  .search-button{
    font-size: 1rem;
    padding: 15px 25px;
  }

  .search-bar {
    font-size: 0.9rem;
    padding: 12px 40px 12px 15px;
  }
}


@media (max-width: 600px) {
  .app-container {
    font-size: 14px;
  }
  
  .content {
    width: 90%;
    padding: 10px;
  }

  h1 {
    font-size: 2rem;
  }

  p {
    font-size: 1rem;
  }

  .search-bar {
    font-size: 0.9rem;
    padding: 12px 40px 12px 15px;
  }

  .search-button,
  .inspire-button {
    font-size: 0.9rem;
    padding: 10px 20px;
  }

  .top-bar {
    padding: 10px;
  }

  .nav-buttons {
    gap: 10px;
  }

  .button {
    font-size: 1rem;
    padding: 8px 16px;
  }

  .suggestions-container {
    font-size: 0.7rem;
  }

  .suggestion-item {
    padding: 10px 15px;
  }

  .search-bar::placeholder {
    font-size: 0.8rem;
  }
  .mode-label {
    display: none;
  }
  
  .mode-button {
    padding: 12px;
  }
  
  .mode-icon {
    width: 24px;
    height: 24px;
  }
  
  .search-mode-slider {
    padding: 0.3rem;
  }
}