:root {
  --background-light: linear-gradient(135deg, #f3f1e6 0%, #e8e5d9 100%);
  --surface-light: rgba(255, 255, 255, 0.95);
  --primary-light: #ff9900;
  --secondary-light: #3498db;
  --text-light: #4a3a1f;
  --border-light: rgba(255, 153, 0, 0.2);
  

  --error-color: #e74c3c;
  --success-color: #2ecc71;
  --warning-color: #f1c40f;
}

.locationPicker {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80vw;
  height: 80vh;
  max-width: 1200px;
  max-height: 800px;
  background: var(--background-light);
  color: var(--text-light);
  z-index: 1050;
  border-radius: 20px;
  box-shadow: 0 8px 32px rgba(74, 58, 31, 0.15);
  overflow: hidden;
  font-family: 'Inter', sans-serif;
  display: flex;
  flex-direction: column;
  border: 1px solid var(--border-light);
  animation: modalAppear 0.3s ease-out;
}

@keyframes modalAppear {
  from {
    opacity: 0;
    transform: translate(-50%, -48%) scale(0.98);
  }
  to {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background: rgba(255, 153, 0, 0.05);
  border-bottom: 1px solid var(--border-light);
  backdrop-filter: blur(8px);
}

.title {
  font-size: 1.5rem;
  font-weight: 600;
  margin: 0;
  color: var(--primary-light);
  display: flex;
  align-items: center;
  gap: 8px;
}

.title::before {
  content: "📍";
  font-size: 1.2em;
  animation: gentleBounce 3s infinite;
}

@keyframes gentleBounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-4px);
  }
  60% {
    transform: translateY(-2px);
  }
}

.closeButton {
  background: rgba(255, 153, 0, 0.1);
  border: 1px solid var(--border-light);
  color: var(--primary-light);
  cursor: pointer;
  padding: 8px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
}

.closeButton:hover {
  background: rgba(255, 153, 0, 0.15);
  transform: rotate(90deg);
}

.mapContainer {
  flex: 1;
  position: relative;
  overflow: hidden;
  border-radius: 0 0 20px 20px;
  background: var(--surface-light);
}

.map {
  width: 100%;
  height: 100%;
  background: var(--surface-light);
}


:global(.leaflet-container) {
  background: var(--surface-light) !important;
  font-family: 'Inter', sans-serif;
}

:global(.leaflet-control-zoom) {
  border: none !important;
  margin: 15px !important;
}

:global(.leaflet-control-zoom a) {
  background: rgba(255, 153, 0, 0.9) !important;
  color: white !important;
  border: none !important;
  width: 36px !important;
  height: 36px !important;
  line-height: 36px !important;
  font-size: 18px !important;
  transition: all 0.3s ease !important;
}

:global(.leaflet-control-zoom a:first-child) {
  border-radius: 8px 8px 0 0 !important;
}

:global(.leaflet-control-zoom a:last-child) {
  border-radius: 0 0 8px 8px !important;
}

:global(.leaflet-control-zoom a:hover) {
  background: rgba(255, 153, 0, 1) !important;
  transform: scale(1.05);
}

.loadingOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(243, 241, 230, 0.9);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1100;
  backdrop-filter: blur(4px);
}

.loader {
  width: 48px;
  height: 48px;
  border: 3px solid rgba(255, 153, 0, 0.3);
  border-radius: 50%;
  border-top-color: var(--primary-light);
  animation: gentleSpin 1s linear infinite;
  margin-bottom: 20px;
}

@keyframes gentleSpin {
  to {
    transform: rotate(360deg);
  }
}

.loadingOverlay span {
  color: var(--text-light);
  font-size: 1.1rem;
  font-weight: 500;
}

.errorOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(243, 241, 230, 0.95);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1100;
  backdrop-filter: blur(4px);
}

.errorContent {
  background: var(--surface-light);
  padding: 30px;
  border-radius: 16px;
  text-align: center;
  max-width: 400px;
  border: 1px solid var(--border-light);
  box-shadow: 0 8px 32px rgba(74, 58, 31, 0.15);
}

.errorContent p {
  color: var(--text-light);
  font-size: 1.1rem;
  margin-bottom: 20px;
}

.errorContent button {
  background: var(--primary-light);
  color: white;
  border: none;
  padding: 12px 24px;
  border-radius: 25px;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 4px 12px rgba(255, 153, 0, 0.2);
}

.errorContent button:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 16px rgba(255, 153, 0, 0.3);
}

/* Light theme map tile adjustments */
:global(.leaflet-tile) {
  /* Subtle adjustments for CartoDB light theme */
  filter: brightness(1.02) contrast(1.02) !important;
  transition: all 0.3s ease !important;
}

/* Custom marker styles */
.customMarker {
  background: linear-gradient(135deg, var(--primary-light) 0%, #ff8800 100%);
  border: none;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0 4px 12px rgba(255, 153, 0, 0.3);
  transition: all 0.3s ease;
}

.customMarker:hover {
  transform: scale(1.1);
  box-shadow: 0 6px 16px rgba(255, 153, 0, 0.4);
}

/* Attribution styles */
:global(.leaflet-control-attribution) {
  background: rgba(255, 255, 255, 0.9) !important;
  color: var(--text-light) !important;
  backdrop-filter: blur(4px);
  padding: 4px 8px !important;
  border-radius: 4px !important;
  font-size: 11px !important;
  border: 1px solid var(--border-light);
}

:global(.leaflet-control-attribution a) {
  color: var(--primary-light) !important;
  text-decoration: none !important;
  transition: color 0.3s ease !important;
}

:global(.leaflet-control-attribution a:hover) {
  color: #ff8800 !important;
  text-decoration: underline !important;
}

/* Smooth transitions */
:global(.leaflet-fade-anim .leaflet-tile),
:global(.leaflet-zoom-anim .leaflet-zoom-animated) {
  transition: all 0.3s ease;
}

/* Responsive styles */
@media (max-width: 768px) {
  .locationPicker {
    top: 55%;
    width: 70vw;
    height: 60vh;
    max-width: none;
    max-height: none;
  }

  .header {
    padding: 15px;
  }

  .title {
    font-size: 1.2rem;
  }

  .closeButton {
    padding: 6px;
  }

  .errorContent {
    padding: 20px;
    margin: 0 20px;
  }

  :global(.leaflet-control-zoom) {
    margin: 10px !important;
  }

  :global(.leaflet-control-zoom a) {
    width: 32px !important;
    height: 32px !important;
    line-height: 32px !important;
    font-size: 16px !important;
  }
}


