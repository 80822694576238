.trialBar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: linear-gradient(45deg, #ffaa00, #e53540);
  height: var(--trial-bar-height);
  z-index: 1001;
  animation: slideDown 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.trialContent {
  max-width: 100%; 
  margin: 0 auto;       
  height: 100%;
  padding: 0 16px;   
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: 'Poppins', sans-serif;
}

.trialText {
  color: white;
  font-size: 0.9rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 8px;
  letter-spacing: 0.3px;
  margin-right: auto;
  padding-left: 8px;
}

.trialText::before {
  content: '🎵';
  animation: bounce 2s infinite;
  font-size: 1.1rem;
}

.exitButton {
  margin-left: 8px;
  margin-right: 8px; 
  background: rgba(255, 255, 255, 0.2);
  border: none;
  border-radius: 50%;
  color: white;
  width: 26px;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.2s ease;
  backdrop-filter: blur(4px);
  order: 1;
  position: relative;
}

.exitButton:hover {
  background: rgba(255, 255, 255, 0.3);
  transform: rotate(90deg);
}

.exitButton svg {
  width: 16px;
  height: 16px;
  stroke-width: 2.5px;
}

.exitButton::after {
  content: 'Create your own';
  position: absolute;
  bottom: -20px;
  left: 50%;
  transform: translateX(-50%) scale(0);
  font-size: 0.75rem;
  white-space: nowrap;
  background: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 4px 8px;
  border-radius: 8px;
  opacity: 0;
  transition: all 0.2s ease;
  pointer-events: none;
}

.exitButton:hover::after {
  opacity: 1;
  transform: translateX(-50%) scale(1);
}

.signUpButton {
  background: linear-gradient(
    135deg,
    rgba(255, 255, 255, 0.95),
    rgba(255, 246, 235, 0.92)
  );
  border: none;
  border-radius: 12px;
  color: #e53540;
  padding: 4px 12px;
  font-size: 0.85rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  height: 28px;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.34, 1.56, 0.64, 1);
  white-space: nowrap;
  box-shadow: 
    0 2px 6px rgba(0, 0, 0, 0.08),
    inset 0 1px 2px rgba(255, 255, 255, 0.9);
  position: relative;
  overflow: hidden;
}

.signUpButton::before {
  content: '✨';
  margin-right: 6px;
  font-size: 0.9em;
  transition: all 0.3s ease;
}

.signUpButton:hover {
  transform: translateY(-1px);
  background: linear-gradient(
    135deg,
    rgba(255, 255, 255, 0.98),
    rgba(255, 246, 235, 0.95)
  );
  box-shadow: 
    0 4px 12px rgba(229, 53, 64, 0.12),
    inset 0 1px 2px rgba(255, 255, 255, 1);
  padding: 4px 14px;
}

.signUpButton:hover::before {
  transform: scale(1.1);
}

.signUpButton:active {
  transform: translateY(1px);
  box-shadow: 
    0 2px 4px rgba(229, 53, 64, 0.1),
    inset 0 1px 2px rgba(255, 255, 255, 0.8);
}

@keyframes slideDown {
  from {
    transform: translateY(-100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes wave {
  0%, 100% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(-20deg);
  }
  75% {
    transform: rotate(20deg);
  }
}

@keyframes bounce {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-3px);
  }
}

@keyframes shine {
  0% {
    transform: rotate(45deg) translateX(-150%);
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    transform: rotate(45deg) translateX(150%);
    opacity: 0;
  }
}

/* Mobile Responsive Design */
@media (max-width: 768px) {
  .trialBar {
    height: 44px;
  }

  .trialContent {
    padding: 0 12px;
  }

  .trialText {
    font-size: 0.85rem;
  }

  .exitButton {
    padding: 0.4rem 0.8rem;
    font-size: 0.8rem;
    height: 28px;
  }

  .signUpButton {
    font-size: 0.85rem;
    padding: 4px 12px;
  }
}

@media (max-width: 480px) {
  .trialBar {
    height: 40px;
  }

  .trialContent {
    padding: 0 8px;
  }

  .trialText {
    font-size: 0.8rem;
  }

  .exitButton {
    padding: 0.3rem 0.6rem;
    font-size: 0.75rem;
    height: 26px;
  }

  .signUpButton {
    font-size: 0.75rem;
    padding: 3px 8px;
    height: 24px;
  }

  .signUpButton::before {
    font-size: 0.8em;
    margin-right: 4px;
  }

  .trialText::before {
    display: none;
  }
}
