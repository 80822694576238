.venueFinderFullscreen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9999;
  background: linear-gradient(135deg, #f3f1e6 0%, #e8e5d9 100%);
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.venueFinderContent {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #4a3a1f;
  font-family: 'Poppins', sans-serif;
  position: relative;
}

.exportButton {
  position: absolute;
  bottom: 20px;
  left: 20px;
  display: inline-flex;
  align-items: center;
  background-color: #4a3a1f;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
}

.exportButton:hover {
  background-color: #362b15;
}

.exportButton::before {
  margin-right: 5px;
}

.purposeFilter {
  position: absolute;
  top: 70px;
  right: 20px;
  z-index: 10001;
  display: flex;
  align-items: center;
}

.purposeFilter select {
  width: auto;
  padding: 8px 30px 8px 12px;
  font-size: 14px;
  border: 2px solid #ff9900;
  border-radius: 15px;
  outline: none;
  color: #4a3a1f;
  background-color: #ffffff;
  cursor: pointer;
  transition: all 0.3s ease;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='10' viewBox='0 0 12 12'%3E%3Cpath fill='%23ff9900' d='M10.293 3.293L6 7.586 1.707 3.293A1 1 0 00.293 4.707l5 5a1 1 0 001.414 0l5-5a1 1 0 10-1.414-1.414z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 10px center;
  background-size: 10px;
}

.purposeFilter select:hover,
.purposeFilter select:focus {
  border-color: #ffaa00;
  box-shadow: 0 2px 4px rgba(255, 153, 0, 0.2);
}

.purposeFilterLabel {
  font-size: 14px;
  font-weight: 600;
  color: #4a3a1f;
  margin-right: 8px;
}

.backButton {
  position: absolute;
  top: 20px;
  left: 9%;
  z-index: 10001;
  background: linear-gradient(45deg, #ffaa00, #ff9900);
  color: #ffffff;
  border: none;
  padding: 12px 24px;
  border-radius: 25px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.backButton:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.2);
  background: linear-gradient(45deg, #ff9900, #ffaa00);
}

.homeButton {
  position: absolute;
  top: 20px;
  left: 1%;
  z-index: 10001;
  background: linear-gradient(45deg, #ffaa00, #ff9900);
  color: #ffffff;
  border: none;
  padding: 12px 24px;
  border-radius: 25px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.homeButton:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.2);
  background: linear-gradient(45deg, #ff9900, #ffaa00);
}

.locationPickerButton {
  position: absolute;
  left: 20%;
  top: 20px;
  z-index: 10001;
  background: linear-gradient(45deg, #ffaa00, #ff9900);
  color: #ffffff;
  border: none;
  padding: 12px 24px;
  border-radius: 25px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.locationPickerButton:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.2);
  background: linear-gradient(45deg, #ff9900, #ffaa00);
}





.hoverInfo {
  position: fixed;
  top: 50%;
  left: 20px;
  transform: translateY(-50%);
  background: rgba(255, 255, 255, 0.95);
  color: #4a3a1f;
  padding: 20px;
  border-radius: 10px;
  width: 350px;
  max-height: 80vh;
  overflow-y: auto;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 11000;
  transition: all 0.3s ease;
  font-size: 14px;
  line-height: 1.4;
  display: flex;
  flex-direction: column;
}

.selectedInfo {
  background: rgba(255, 255, 255, 0.98);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.selectedInfo::-webkit-scrollbar {
  width: 8px;
}

.selectedInfo::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.selectedInfo::-webkit-scrollbar-thumb {
  background: #F9A826;
  border-radius: 4px;
}

.selectedInfo::-webkit-scrollbar-thumb:hover {
  background: #f8b84e;
}

.nodeInfo h3 {
  color: #ff9900;
  font-size: 2rem;
  font-weight: 600;
  margin-bottom: 10px;
  text-align: center;
}

.nodeInfo p {
  font-size: 0.8rem;
  font-weight: 400;
  margin-bottom: 10px;
  text-align: left;
}

.nodeInfo h4 {
  color: #4a3a1f;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.nodeInfo h4::before {
  margin-right: 5px;
}

.nodeInfo .section {
  border-radius: 5px;
  padding: 15px;
  margin-bottom: 20px;
}

.nodeInfo .section strong {
  font-weight: 600;
}

.nodeInfo .section ul {
  margin: 0;
  padding-left: 20px;
}

.nodeInfo .audienceInfo {
  background-color: #FFFFFF;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 10px;
}

.nodeInfo .mapLink {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #ff9900;
  color: #fff;
  padding: 10px 20px;
  border-radius: 5px;
  text-decoration: none;
  margin-top: 20px;
  transition: background-color 0.3s;
}

.nodeInfo .mapLink:hover {
  background-color: #e68a00;
}

.nodeInfo .mapLink::before {
  margin-right: 5px;
}

.nodeInfo .noteButton {
  display: inline-flex;
  align-items: center;
  background-color: #4a3a1f;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 20px;
  justify-content: center;
  align-self: center;
  transition: background-color 0.3s;
}

.nodeInfo .noteButton:hover {
  background-color: #362b15;
}

.nodeInfo .noteButton::before {
  margin-right: 5px;
}

.nodeInfo .noteButton:active {
  background-color: red;
}

.nodeInfo ::-webkit-scrollbar {
  width: 8px;
}

.nodeInfo ::-webkit-scrollbar-track {
  background-color: #f5f5f5;
}

.nodeInfo ::-webkit-scrollbar-thumb {
  background-color: #ff9900;
  border-radius: 4px;
}

.section {
  margin-bottom: 15px;
}

.section ul {
  margin: 0;
  padding-left: 20px;
}

.section li {
  margin-bottom: 5px;
}

.audienceInfo {
  background: rgba(255, 153, 0, 0.1);
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 10px;
}

.audienceInfo p {
  margin: 5px 0;
}

.loadingContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-size: 24px;
  color: #4a3a1f;
}

.errorContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.warning {
  font-size: 0.8rem;
  color: red;
  text-align: center;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
}

.errorTitle {
  color: #ff9900;
  font-size: 24px;
  margin-bottom: 20px;
}

.searchForm {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 10001;
  display: flex;
  align-items: center;
}

.searchInput {
  padding: 10px 15px;
  border: 2px solid #ff9900;
  border-radius: 25px 0 0 25px;
  font-size: 16px;
  outline: none;
  width: 200px;
}

.searchButton {
  background: linear-gradient(45deg, #ffaa00, #ff9900);
  color: #ffffff;
  border: none;
  padding: 12px 24px;
  border-radius: 0 25px 25px 0;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  transition: all 0.3s ease;
}

.searchButton:hover {
  background: linear-gradient(45deg, #ff9900, #ffaa00);
}

.noDataContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-size: 24px;
  color: #4a3a1f;
  text-align: center;
}

.legend {
  position: absolute;
  bottom: 20px;
  right: 20px;
  background: rgba(255, 255, 255, 0.9);
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
}

.legend.hidden {
  transform: translateY(100%);
}

.legend h4 {
  margin-top: 0;
  margin-bottom: 10px;
  font-size: 16px;
  color: #4a3a1f;
}

.legendItem {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.legendColor {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-right: 10px;
}


.notFoundOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(135deg, #f3f1e6 0%, #e8e5d9 100%);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.notFoundContent {
  background: white;
  padding: 40px;
  border-radius: 20px;
  text-align: center;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  animation: slideUp 0.5s ease-out forwards;
  max-width: 400px;
  width: 90%;
}

.notFoundIcon {
  font-size: 64px;
  margin-bottom: 20px;
  animation: pulse 2s infinite;
}

.notFoundContent h2 {
  color: #ff9900;
  font-size: 28px;
  margin: 0 0 10px;
  font-weight: 600;
}

.notFoundContent p {
  color: #666;
  font-size: 16px;
  margin: 0 0 20px;
}

.redirectMessage {
  color: #999;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.loadingDots {
  display: flex;
  gap: 2px;
}

.loadingDots span {
  animation: dots 1.4s infinite;
  opacity: 0;
}

.loadingDots span:nth-child(2) {
  animation-delay: 0.2s;
}

.loadingDots span:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes slideUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes dots {
  0%, 100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}




:global(.force-graph-container) {
  background: transparent !important;
  width: 100% !important;
  height: 100% !important;
  border: none !important;
}

:global(.force-graph-container canvas) {
  width: 100% !important;
  height: 100% !important;
  border-radius: 0 !important;
}

@media (max-width: 768px) {
  .backButton {
    top: 8%;
    left: 13%;
    padding: 10px;
    font-size: 0;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .backButton .icon {
    font-size: 20px;
    gap: 15px;
  }

  .homeButton {
    top: 8%;
    left: 1%;
    padding: 10px;
    font-size: 0;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .homeButton .icon {
    font-size: 20px;
    gap: 15px;
  }

  .searchForm {
    top: 1%;
    left: 50%;
    transform: translateX(-50%);
    right: auto;
    width: 90%;
  }

  .searchInput {
    flex: 1;
    font-size: 16px;
    border-radius: 20px 0 0 20px;
  }

  .searchButton {
    padding: 12px 20px;
    font-size: 16px;
    border-radius: 0 20px 20px 0;
  }

  .hoverInfo {
    width: 90%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-height: 70vh;
  }

  .purposeFilter {
    top: 8%;
    right: 40%;
    transform: translateX(50%);
    width: 70%;
    justify-content: center;
  }

  .purposeFilter select {
    width: 100%;
    font-size: 14px;
    border-radius: 20px;
    padding: 10px 30px 10px 15px;
    background-position: right 10px center;
    background-size: 10px;
  }

  .legend {
    bottom: 0;
    right: 0;
    left: 0;
    width: 100%;
    border-radius: 20px 20px 0 0;
    padding: 10px;
    text-align: left;
    transform: translateY(100%);
    transition: transform 0.3s ease-in-out;
  }

  .legend.visible {
    transform: translateY(0);
  }

  .legend h4 {
    font-size: 1rem;
    margin-bottom: 5px;
  }

  .legendItem {
    font-size: 0.9rem;
    margin: 3px 0;
  }

  .legendColor {
    width: 15px;
    height: 15px;
    margin-right: 5px;
  }

  .exportButton {
    bottom: 2.5%;
    left: 50%;
    transform: translateX(-50%);
    padding: 12px 24px;
    content: '📜';
    border-radius: 20px;
  }

  .nodeInfoHeader h2 {
    font-size: 1.5rem;
  }

  .notFoundContent {
    padding: 30px;
    width: 85%;
  }

  .notFoundIcon {
    font-size: 48px;
  }

  .notFoundContent h2 {
    font-size: 24px;
  }

  .notFoundContent p {
    font-size: 14px;
  }

  .locationPickerButton {
    top: 90%;
    left: 1%;
    padding: 10px;
    font-size: 0;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .locationPickerButton::before {
    content: "";
    margin: 0;
  }
}