body, html {
  margin: 0;
  padding: 0;
  min-height: 100%;
}

#root {
  min-height: 100%;
}

.app-container {
  position: relative;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}

.parent-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 100%;
  width: 100%;
  z-index: 2;
}

.background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: #f3eddf;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}

.content {
  background-color: rgba(255, 255, 255, 1);
  border-radius: 10px;
  padding: 50px;
  z-index: 1000; /* Ensure content stays above portal */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: auto;
  pointer-events: auto;
}

.knowledge-graph-background {
  transition: transform 1.5s cubic-bezier(0.4, 0, 0.2, 1);
}

#portal-root {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1000;
  touch-action: auto !important;
  pointer-events: none;
}

#portal-root > div {
  width: 100%;
  height: 100%;
  background: #fff; 
  touch-action: auto !important;
  pointer-events: auto;
}

.portal-content {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: visible;
  touch-action: auto !important;
  pointer-events: auto !important;
}

.route-transition {
  width: 100%;
  height: 100%;
  position: relative;
  animation: routeFadeIn 0.3s ease-out;
}

@keyframes routeFadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Ensure all interactive elements are clickable */
button,
input,
.search-bar,
.suggestion-item,
.mode-button,
.inspire-button,
.search-button {
  touch-action: auto !important;
  pointer-events: auto !important;
  cursor: pointer;
}



@media screen and (max-width: 768px) {
  #portal-root {
    overflow: visible;
  }

  #portal-root > div {
    overflow: visible;
    position: relative;
  }

  #portal-root h2 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
    opacity: 1 !important;
    visibility: visible !important;
  }

  #portal-root h3 {
    font-size: 1.2rem;
    margin-bottom: 0.8rem;
    opacity: 1 !important;
    visibility: visible !important;
  }


  #portal-root .tracksListContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    opacity: 1 !important;
    visibility: visible !important;
  }

  #portal-root .tracksList {
    width: 100%;
    opacity: 1 !important;
    visibility: visible !important;
  }

  /* Ensure all touch events work */
  #portal-root * {
    pointer-events: auto !important;
    touch-action: auto !important;    -webkit-tap-highlight-color: rgba(0,0,0,0);
  }

  /* Force visibility of core sections */
  #portal-root .coreValuesGrid,
  #portal-root .card,
  #portal-root .tracksListContainer,
  #portal-root .tracksList,
  #portal-root .trackItem,
  #portal-root .expandableSection,
  #portal-root .mobileSection {
    opacity: 1 !important;
    visibility: visible !important;
    transform: none !important;
    transition: none !important;
    display: block !important;
    pointer-events: auto !important;
  }

  /* Ensure proper track list layout */
  #portal-root .tracksListContainer {
    display: flex !important;
    flex-direction: column !important;
    width: 100% !important;
  }

  /* Force core values grid layout */
  #portal-root .coreValuesGrid {
    display: grid !important;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr)) !important;
    gap: 1rem !important;
    width: 100% !important;
    margin: 1rem 0 !important;
  }


  #portal-root .section,
  #portal-root .expandableSection,
  #portal-root .middleRow,
  #portal-root .bottomRow {
    display: block !important;
    visibility: visible !important;
    opacity: 1 !important;
  }

  .portal-content,
  .route-transition,
  .portal-content > div,
  .route-transition > div {
    transform: none !important;
    opacity: 1 !important;  
    transition: none !important;
  }
  

  .portal-content * {
    visibility: visible !important;
    opacity: 1 !important;
  }
}