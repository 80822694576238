@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

.about-page-container {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
}

.about-page-scroll {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    box-sizing: border-box;
    padding: 2rem;
}

.about-page {
    font-family: 'Poppins', sans-serif;
    color: #333333;
    line-height: 1.6;
    max-width: 1200px;
    margin: 0 auto;
}

.about-page-scroll::-webkit-scrollbar {
    width: 8px;
}

.about-page-scroll::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.about-page-scroll::-webkit-scrollbar-thumb {
    background: #F9A826;
    border-radius: 4px;
}

.about-page-scroll::-webkit-scrollbar-thumb:hover {
    background: #f8b84e;
}

.about-header {
    text-align: center;
    margin-bottom: 3rem;
}

.logo {
    width: 60%;
    padding-bottom: 2rem;
    height: auto;
}

.main-title {
    font-size: 2.5rem;
    font-weight: 700;
    color: #333333;
    margin-bottom: 0.5rem;
}

.tagline {
    font-size: 1.8rem;
    color: #e43541;
}

.section {
    margin-bottom: 4rem;
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 0.5s ease, transform 0.5s ease;
}

.section.visible {
    opacity: 1;
    transform: translateY(0);
}

.section-header {
    display: flex;
    align-items: center;
    margin-bottom: 1.5rem;
}

.section-icon {
    font-size: 2rem;
    margin-right: 1rem;
    color: #F9A826;
}

.section-title {
    font-size: 2rem;
    color: #333333;
    font-weight: 600;
}

.vision-description,
.step-description,
.persona-description {
    color: #666666;
    margin-bottom: 1rem;
    text-align: left;
    padding-bottom: 1rem;}



.features-content {
padding: 2rem 0;
max-width: 1200px;
margin: 0 auto;
}

.journey-intro {
text-align: center;
margin-bottom: 4rem;
}

.journey-flow {
position: relative;
padding: 2rem 0;
}


.journey-flow::before {
content: '';
position: absolute;
top: 0;
left: calc(50% - 1px); 
height: 100%;
width: 2px;
background: linear-gradient(
    to bottom,
    transparent,
    #F9A826,
    #F9A826,
    transparent
);
}
  
.journey-stage {
display: flex;
align-items: center;
gap: 4rem; 
margin-bottom: 6rem; 
position: relative;
opacity: 0;
transform: translateY(20px);
animation: fadeInUp 0.5s ease forwards;
width: 100%;
}


.stage-left {
justify-content: flex-end;
padding-right: calc(50% + 2rem);
}

.stage-right {
justify-content: flex-start;
padding-left: calc(50% + 2rem);
}

.stage-content {
background: white;
padding: 2.5rem; 
border-radius: 12px;
box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
width: 100%;
max-width: 500px; 
}

.stage-content.coming-soon {
  opacity: 0.7;
  position: relative;
}

.stage-content.coming-soon::before {
  content: '🚧 In Development';
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: #F9A826;
  color: white;
  padding: 0.25rem 0.75rem;
  border-radius: 1rem;
  font-size: 0.8rem;
  font-weight: 500;
}

.stage-header {
display: flex;
align-items: center;
gap: 1rem;
margin-bottom: 1.5rem; 
}

.stage-number {
background: #F9A826;
color: white;
padding: 0.25rem 0.75rem;
border-radius: 1rem;
font-size: 0.8rem;
font-weight: 500;
margin-right: 1rem;
}

.stage-icon {
font-size: 2rem;
}

.stage-title {
font-size: 1.5rem;
font-weight: 600;
color: #333;
line-height: 1.3;
}

.stage-description {
color: #666;
margin-bottom: 2rem;
line-height: 1.6;
font-size: 1.1rem;
}

.stage-feature {
background: #f8f9fa;
padding: 1.5rem;
border-radius: 8px;
margin-bottom: 1.5rem;
}

.feature-title {
font-size: 1.2rem;
font-weight: 600;
margin-bottom: 1rem;
color: #333;
}

.feature-description {
color: #666;
line-height: 1.6;
margin-bottom: 1rem;
}

.feature-details {
display: flex;
flex-wrap: wrap;
gap: 0.8rem;
margin-top: 1.5rem;
}

.feature-tag {
background: #fff;
padding: 0.5rem 1rem;
border-radius: 20px;
font-size: 0.9rem;
color: #666;
box-shadow: 0 1px 3px rgba(0,0,0,0.1);
}

.stage-insight {
display: flex;
align-items: center;
gap: 0.8rem;
color: #666;
font-style: italic;
margin-top: 1.5rem;
padding-top: 1.5rem;
border-top: 1px solid #eee;
}

.stage-visual {
position: absolute;
display: flex;
align-items: center;
justify-content: center;
width: 60px;
height: 60px;
background: white;
border-radius: 50%;
box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.stage-left .stage-visual {
right: calc(50% - 30px);
}

.stage-right .stage-visual {
left: calc(50% - 30px);
}

.journey-visual {
color: #F9A826;
}


@keyframes pulse {
    0% { transform: scale(1); }
    50% { transform: scale(1.05); }
    100% { transform: scale(1); }
}


.cta-section {
    text-align: center;
    margin-top: 4rem;
}

.cta-title {
    font-size: 2rem;
    margin-bottom: 2rem;
    color: #333333;
}

.cta-button {
    background-color: #F9A826;
    color: white;
    border: none;
    padding: 1rem 2rem;
    font-size: 1.2rem;
    border-radius: 50px;
    cursor: pointer;
    transition: all 0.3s ease;
}

.cta-button:hover {
    background-color: #f8b84e;
    transform: translateY(-2px);
}

.artist-benefits-content {
    margin-top: 2rem;
}

.benefits-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
    margin-top: 2rem;
}

.benefit-card {
    background: #FFFFFF;
    border-radius: 8px;
    padding: 1.5rem;
    text-align: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.benefit-icon {
    font-size: 2.5rem;
    margin-bottom: 1rem;
    color: #F9A826;
}

.benefit-card h4 {
    font-size: 1.2rem;
    margin-bottom: 0.5rem;
    color: #333333;
}

.benefit-card p {
    font-size: 0.9rem;
    color: #666666;
}

.difference-content {
    margin-top: 2rem;
}

.example-block {
    margin-top: 1rem;
    padding: 1.5rem;
    background: white;
    border-radius: 8px;
    display: flex;
    align-items: center;
    gap: 1rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.example-icon {
    color: #F9A826;
}

.example-text {
    color: #666666;
    font-style: italic;
    line-height: 1.4;
}

.impact-highlight {
    margin-top: 4rem;
}

.highlight-title {
    text-align: center;
    font-size: 2rem;
    color: #333333;
    margin-bottom: 2rem;
    font-weight: 600;
}


@keyframes softPulse {
    0% {
        box-shadow: 0 0 0 0 rgba(249, 168, 38, 0.4);
    }
    50% {
        box-shadow: 0 0 0 15px rgba(249, 168, 38, 0);
    }
    100% {
        box-shadow: 0 0 0 0 rgba(249, 168, 38, 0);
    }
}


.overview-grid,
.feature-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 2rem;
    justify-content: center;
    align-items: stretch;
    text-align: center;
}

.overview-feature,
.feature-card {
    background-color: #ffffff;
    border-radius: 12px;
    padding: 2rem;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
}

.overview-feature-icon,
.feature-icon {
    font-size: 3rem;
    margin-bottom: 1.2rem;
    color: #F9A826;
}

.overview-feature-title,
.feature-title {
    font-size: 1.4rem;
    font-weight: 600;
    color: #333;
    margin-bottom: 1rem;
}

.overview-feature-description,
.feature-description {
    font-size: 1rem;
    color: #666;
    line-height: 1.6;
    flex-grow: 1;
}

@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.section-content > * {
    animation: fadeInUp 0.5s ease forwards;
    opacity: 0;
}

.section-content > *:nth-child(1) { animation-delay: 0.1s; }
.section-content > *:nth-child(2) { animation-delay: 0.2s; }
.section-content > *:nth-child(3) { animation-delay: 0.3s; }
.section-content > *:nth-child(4) { animation-delay: 0.4s; }
.section-content > *:nth-child(5) { animation-delay: 0.5s; }

.founding-story-content {
    position: relative;
    min-height: auto;
    overflow: hidden;
}

.story-flow {
    position: relative;
    max-width: 1200px;
    margin: 0 auto;
}

.story-moment {
    min-height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    perspective: 1000px;
}

/* Content Blocks */
.content-reveal {
    max-width: 800px;
    text-align: center;
    padding: 1rem;
    background: white;
    border-radius: 24px;
    transform-style: preserve-3d;
    transform: translateY(var(--translate-y, 20px)) rotateX(var(--rotate-x, 0deg));
    opacity: 0;
    animation: contentReveal 1s cubic-bezier(0.23, 1, 0.32, 1) forwards;
}

/* Icon Styles */
.moment-icon {
    color: #F9A826;
    margin-bottom: 2rem;
    position: relative;
    z-index: 2;
}

.moment-icon.pulse {
    animation: iconGlow 2s infinite;
}

.moment-icon::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 60px;
    height: 60px;
    background: rgba(249, 168, 38, 0.1);
    border-radius: 50%;
    transform: translate(-50%, -50%);
    z-index: -1;
}

/* Typography */
.spark h3,
.revolution h3 {
    font-size: 3rem;
    color: #333333;
    margin-bottom: 1.5rem;
    font-weight: 700;
    line-height: 1.2;
    background: linear-gradient(45deg, #333333, #666666);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

.spark-text,
.revolution-text {
    font-size: 1.4rem;
    line-height: 1.7;
    color: #666666;
    margin-bottom: 2.5rem;
    max-width: 700px;
    margin-left: auto;
    margin-right: auto;
}

/* Call to Action */
.call-to-action {
    margin-top: 3rem;
    position: relative;
}

.cta-button {
    padding: 1.4rem 3rem;
    font-size: 1.2rem;
    background: linear-gradient(45deg, #F9A826, #ffb846);
    color: white;
    border: none;
    border-radius: 50px;
    display: inline-flex;
    align-items: center;
    gap: 1rem;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.3s cubic-bezier(0.23, 1, 0.32, 1);
    position: relative;
    overflow: hidden;
}

.cta-button::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(45deg, #ffb846, #F9A826);
    opacity: 0;
    transition: opacity 0.3s ease;
}

.cta-button:hover {
    color: white;
    transform: translateY(-2px);
    box-shadow: 
        0 10px 20px rgba(249, 168, 38, 0.2),
        0 6px 6px rgba(249, 168, 38, 0.1);
}

.cta-button:hover::before {
    opacity: 1;
}

.cta-button svg {
    transition: transform 0.3s ease;
}

.cta-button:hover svg {
    transform: translateX(4px);
}

/* Animations */
@keyframes contentReveal {
    0% {
        opacity: 0;
        transform: translateY(40px) rotateX(10deg);
    }
    100% {
        opacity: 1;
        transform: translateY(0) rotateX(0deg);
    }
}

@keyframes iconGlow {
    0% {
        transform: scale(1);
        filter: brightness(1);
    }
    50% {
        transform: scale(1.1);
        filter: brightness(1.2);
    }
    100% {
        transform: scale(1);
        filter: brightness(1);
    }
}

/* Responsive */

/* Base Styles & Desktop */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

.about-page-container {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
}

.about-page-scroll {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    box-sizing: border-box;
    padding: 2rem;
}

.about-page {
    font-family: 'Poppins', sans-serif;
    color: #333333;
    line-height: 1.6;
    max-width: 1200px;
    margin: 0 auto;
}

.about-page-scroll::-webkit-scrollbar {
    width: 8px;
}

.about-page-scroll::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.about-page-scroll::-webkit-scrollbar-thumb {
    background: #F9A826;
    border-radius: 4px;
}

.about-page-scroll::-webkit-scrollbar-thumb:hover {
    background: #f8b84e;
}

.about-header {
    text-align: center;
    margin-bottom: 3rem;
}

.logo {
    width: 60%;
    padding-bottom: 2rem;
    height: auto;
}

.main-title {
    font-size: 2.5rem;
    font-weight: 700;
    color: #333333;
    margin-bottom: 0.5rem;
}

.tagline {
    font-size: 1.8rem;
    color: #e43541;
}

.section {
    margin-bottom: 4rem;
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 0.5s ease, transform 0.5s ease;
}

.section.visible {
    opacity: 1;
    transform: translateY(0);
}

/* Desktop Sections */
.section-header {
    display: flex;
    align-items: center;
    margin-bottom: 1.5rem;
}

.section-icon {
    font-size: 2rem;
    margin-right: 1rem;
    color: #F9A826;
}

.section-title {
    font-size: 2rem;
    color: #333333;
    font-weight: 600;
}

.overview-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
    padding: 0.5rem;
}

.journey-flow {
    position: relative;
    padding: 2rem 0;
}

.journey-flow::before {
    content: '';
    position: absolute;
    top: 0;
    left: calc(50% - 1px);
    height: 100%;
    width: 2px;
    background: linear-gradient(to bottom, transparent, #F9A826, #F9A826, transparent);
}

.journey-stage {
    display: flex;
    align-items: center;
    gap: 4rem;
    margin-bottom: 6rem;
    position: relative;
    opacity: 0;
    transform: translateY(20px);
    animation: fadeInUp 0.5s ease forwards;
    width: 100%;
}

/* Mobile Styles */
@media screen and (max-width: 768px) {
    .about-page-container {
        width: 100%;
        height: 100%;
        position: relative;
        overflow-x: hidden;
    }

    .about-page-scroll {
        height: 100%;
        overflow-y: auto;
        padding: 1rem 1rem 5rem 1rem;
        -webkit-overflow-scrolling: touch;
        scroll-behavior: smooth;
    }

    .about-page {
        max-width: 100%;
        margin: 0;
        padding-bottom: 4rem;
    }

    /* Header Section */
    .about-header {
        padding: 1rem 0.5rem;
        margin-bottom: 2rem;
    }

    .logo {
        width: 70%;
        max-width: 200px;
        margin: 0 auto 1rem;
    }

    .tagline {
        font-size: 1.1rem;
        margin: 0;
        color: #e43541;
        font-style: italic;
    }

    /* Vision Section */
    .vision-content {
        padding: 0;
    }

    .vision-description {
        padding: 0 0.5rem;
    }

    .vision-description p {
        font-size: 0.95rem;
        line-height: 1.6;
        margin-bottom: 2rem;
        color: #666;
    }

    /* Overview Grid */
    .overview-grid {
        display: grid;
        grid-template-columns: 1fr;
        gap: 1rem;
        padding: 0.5rem;
    }

    .overview-feature {
        background: white;
        border-radius: 16px;
        padding: 1.5rem;
        box-shadow: 0 4px 15px rgba(0, 0, 0, 0.08);
        transition: transform 0.3s ease;
    }

    .overview-feature-icon {
        margin-bottom: 1rem;
    }

    .overview-feature-title {
        font-size: 1.2rem;
        margin-bottom: 0.75rem;
        color: #333;
    }

    .overview-feature-description {
        font-size: 0.9rem;
        line-height: 1.5;
        color: #666;
    }

    /* Journey Timeline */
    .journey-flow {
        position: relative;
        padding: 1rem 0;
        margin: 2rem 0;
    }

    .journey-flow::before {
        content: '';
        position: absolute;
        left: 20px;
        width: 2px;
        height: calc(100% - 2rem);
        background: linear-gradient(180deg, 
            transparent 0%,
            #F9A826 15%,
            #F9A826 85%,
            transparent 100%
        );
    }

    .journey-stage {
        position: relative;
        padding-left: 3rem;
        margin-bottom: 2.5rem;
        animation: slideIn 0.5s ease forwards;
    }

    @keyframes slideIn {
        to {
            opacity: 1;
            transform: translateX(0);
        }
    }

    .stage-left,
    .stage-right {
        padding-right: 0;
        padding-left: 3rem;
        justify-content: flex-start;
    }

    .stage-content {
        background: white;
        border-radius: 16px;
        padding: 1.25rem;
        width: 100%;
        box-shadow: 0 4px 15px rgba(0, 0, 0, 0.08);
    }

    .stage-header {
        display: flex;
        align-items: center;
        gap: 0.75rem;
        margin-bottom: 1rem;
    }

    .stage-icon {
        font-size: 1.5rem;
    }

    .stage-number {
        font-size: 0.8rem;
        font-weight: 600;
        color: #F9A826;
    }

    .stage-title {
        font-size: 1.2rem;
        color: #333;
        margin: 0;
    }

    .stage-description {
        font-size: 0.9rem;
        line-height: 1.6;
        color: #666;
        margin-bottom: 1rem;
    }

    /* Stage Features */
    .stage-feature {
        background: rgba(249, 168, 38, 0.05);
        border-radius: 12px;
        padding: 1rem;
        margin-top: 1rem;
    }

    .feature-title {
        font-size: 1rem;
        color: #333;
        margin-bottom: 0.5rem;
    }

    .feature-description {
        font-size: 0.85rem;
        color: #666;
        line-height: 1.5;
        margin-bottom: 1rem;
    }

    .feature-details {
        display: flex;
        flex-wrap: wrap;
        gap: 0.5rem;
    }

    .feature-tag {
        font-size: 0.75rem;
        padding: 0.4rem 0.8rem;
        background: white;
        border-radius: 20px;
        color: #666;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    }

    /* Visual Elements */
    .stage-visual {
        position: absolute;
        left: 6px;
        top: 0;
        width: 32px;
        height: 32px;
        background: white;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
    }

    .journey-visual {
        width: 20px;
        height: 20px;
        color: #F9A826;
    }

    /* Stage Insights */
    .stage-insight {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        margin-top: 1rem;
        padding-top: 1rem;
        border-top: 1px solid rgba(0, 0, 0, 0.1);
        font-size: 0.85rem;
        color: #666;
        font-style: italic;
    }

    /* Revolution Section */
    .story-moment.revolution {
        padding: 1.5rem 0.5rem 3rem 0.5rem;
        background: none;
    }

    .content-reveal {
        background: none;
        padding: 0;
        box-shadow: none;
    }

    .content-reveal h3 {
        font-size: 1.4rem;
        color: #333;
        margin-bottom: 1rem;
        text-align: center;
    }

    .revolution-text {
        font-size: 0.95rem;
        line-height: 1.6;
        color: #666;
        margin-bottom: 1.5rem;
        text-align: center;
        padding: 0 1rem;
    }

    .call-to-action {
        padding: 0 1rem;
    }

    .cta-button {
        width: 100%;
        padding: 1rem 1.5rem;
        font-size: 1rem;
        border-radius: 12px;
        background: linear-gradient(135deg, #F9A826, #f8b84e);
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0.5rem;
        transition: all 0.3s ease;
        border: none;
        text-align: center;
    }

    .cta-button:active {
        transform: scale(0.98);
        background: linear-gradient(135deg, #f8b84e, #F9A826);
    }
}


@media screen and (max-width: 380px) {
    .about-page-scroll {
        padding: 0.75rem 0.75rem 5rem 0.75rem;
    }

    .journey-stage {
        padding-left: 2.5rem;
    }

    .stage-left,
    .stage-right {
        padding-left: 2.5rem;
    }

    .stage-content {
        padding: 1rem;
    }

    .stage-title {
        font-size: 1.1rem;
    }

    .content-reveal h3 {
        font-size: 1.3rem;
    }

    .revolution-text {
        font-size: 0.9rem;
        padding: 0 0.5rem;
    }

    .call-to-action {
        padding: 0 0.5rem;
    }

    .feature-tag {
        font-size: 0.7rem;
        padding: 0.3rem 0.6rem;
    }
}


@supports (padding: max(0px)) {
    .about-page-scroll {
        padding-bottom: max(5rem, calc(5rem + env(safe-area-inset-bottom)));
    }
}

@supports (-webkit-touch-callout: none) {
    .about-page-scroll {
        padding-bottom: calc(5rem + 35px);
    }
}

@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}