.staytuned__page {
  display: flex;
  justify-content: center;
  align-items: center;
}

.staytuned__container {
  background: rgba(255, 255, 255, 0.95);
  border-radius: 24px;
  width: min(98%, 600px);
  position: relative;
  z-index: 1;
  animation: slideUp 0.6s ease-out;
}

.staytuned__content {
  text-align: center;
  padding: 2.5rem;
}

.staytuned__logo {
  width: 180px;
  height: auto;
  margin: 0 auto 2rem;
  display: block;
  filter: drop-shadow(0 4px 8px rgba(0, 0, 0, 0.1));
}

.staytuned__back {
  position: absolute;
  top: 1.5rem;
  left: 1.5rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: #4a3a1f;
  text-decoration: none;
  font-size: 0.9rem;
  opacity: 0.7;
  transition: all 0.3s ease;
}

.staytuned__back:hover {
  opacity: 1;
  transform: translateX(-3px);
}

.staytuned__title {
  font-size: 2.5rem;
  font-weight: 700;
  color: #4a3a1f;
  margin-bottom: 1rem;
  line-height: 1.2;
}

.staytuned__highlight {
  background: linear-gradient(135deg, #ffaa00, #e53540);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.staytuned__subtitle {
  color: #5a4a2f;
  font-size: 1.1rem;
  margin-bottom: 2rem;
  opacity: 0.8;
}

.staytuned__form {
  max-width: 450px;
  margin: 0 auto;
}

.staytuned__input_wrapper {
  position: relative;
  margin-bottom: 1rem;
}

.staytuned__input {
  width: 100%;
  padding: 1.2rem 1.5rem;
  padding-right: 4rem;
  border: 2px solid rgba(90, 74, 47, 0.2);
  border-radius: 14px;
  font-size: 1rem;
  color: #4a3a1f;
  transition: all 0.3s ease;
  background: white;
}

.staytuned__input:focus {
  outline: none;
  border-color: #ffaa00;
  box-shadow: 0 0 0 3px rgba(255, 170, 0, 0.1);
}

.staytuned__submit {
  position: absolute;
  right: 0.5rem;
  top: 50%;
  transform: translateY(-50%);
  background: linear-gradient(135deg, #ffaa00, #e53540);
  color: white;
  border: none;
  width: 45px;
  height: 45px;
  border-radius: 12px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
}

.staytuned__submit:hover {
  transform: translateY(-50%) scale(1.05);
  box-shadow: 0 4px 12px rgba(255, 170, 0, 0.2);
}

.staytuned__submit:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.staytuned__loader {
  width: 20px;
  height: 20px;
  border: 2px solid rgba(255, 255, 255, 0.3);
  border-top-color: white;
  border-radius: 50%;
  animation: spin 0.8s linear infinite;
}

.staytuned__error {
  color: #e53540;
  font-size: 0.9rem;
  margin-top: 0.5rem;
}

.staytuned__success {
  text-align: center;
  padding: 2rem;
  animation: fadeIn 0.5s ease-out;
}

.staytuned__success_icon {
  font-size: 3rem;
  margin-bottom: 1rem;
  animation: bounce 1s ease infinite;
}

.staytuned__features {
  margin-top: 3rem;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 1.5rem;
  padding: 0 1rem;
}

.staytuned__feature {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  font-size: 0.95rem;
  color: #5a4a2f;
  padding: 1rem;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 12px;
  border: 2px solid rgba(90, 74, 47, 0.1);
  transition: all 0.3s ease;
}

.staytuned__feature:hover {
  transform: translateY(-2px);
  border-color: #ffaa00;
  background: white;
}

.staytuned__feature_icon {
  font-size: 1.2rem;
}

@keyframes slideUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes bounce {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

@media (max-width: 768px) {
  .staytuned__container {
    width: 95%;
    margin: 1rem;
  }

  .staytuned__content {
    padding: 2rem 1.5rem;
  }

  .staytuned__title {
    font-size: 2rem;
  }

  .staytuned__subtitle {
    font-size: 1rem;
  }

  .staytuned__features {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 480px) {
  .staytuned__container {
    width: 92%;
    margin: 0.5rem;
    border-radius: 20px;
  }

  .staytuned__content {
    padding: 1.5rem 1rem;
  }

  .staytuned__logo {
    width: 140px;
    margin-bottom: 1.5rem;
  }

  .staytuned__title {
    font-size: 1.8rem;
    margin-bottom: 0.75rem;
  }

  .staytuned__subtitle {
    font-size: 0.95rem;
    margin-bottom: 1.5rem;
    padding: 0 0.5rem;
  }

  .staytuned__input {
    padding: 1rem 1.2rem;
    padding-right: 3.5rem;
    font-size: 0.95rem;
    border-radius: 12px;
  }

  .staytuned__submit {
    width: 38px;
    height: 38px;
    border-radius: 10px;
  }

  .staytuned__features {
    margin-top: 2rem;
    gap: 1rem;
    padding: 0;
  }

  .staytuned__feature {
    padding: 0.75rem;
    font-size: 0.9rem;
    border-radius: 10px;
  }

  .staytuned__feature_icon {
    font-size: 1.1rem;
  }

  .staytuned__back {
    top: 1rem;
    left: 1rem;
    font-size: 0.85rem;
  }

  .staytuned__error {
    font-size: 0.85rem;
  }

  .staytuned__success {
    padding: 1.5rem;
  }

  .staytuned__success h2 {
    font-size: 1.4rem;
  }

  .staytuned__success p {
    font-size: 0.95rem;
  }

  .staytuned__success_icon {
    font-size: 2.5rem;
  }
}

/* For very small devices */
@media (max-width: 350px) {
  .staytuned__container {
    width: 95%;
    margin: 0.3rem;
  }

  .staytuned__content {
    padding: 1.2rem 0.8rem;
  }

  .staytuned__logo {
    width: 120px;
  }

  .staytuned__title {
    font-size: 1.6rem;
  }

  .staytuned__subtitle {
    font-size: 0.9rem;
  }

  .staytuned__input {
    padding: 0.9rem 1rem;
    font-size: 0.9rem;
  }

  .staytuned__feature {
    padding: 0.6rem;
    font-size: 0.85rem;
  }
}

/* For landscape orientation on mobile */
@media (max-height: 600px) and (orientation: landscape) {
  .staytuned__page {
    padding: 1rem;
    min-height: 100vh;
    align-items: flex-start;
  }

  .staytuned__logo {
    width: 120px;
    margin-bottom: 1rem;
  }

  .staytuned__features {
    grid-template-columns: repeat(3, 1fr);
    margin-top: 1.5rem;
  }

  .staytuned__content {
    padding: 1.5rem;
  }
}
