/* Loading  Files */
.loadingSinner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.spinner { 
  border: 4px solid rgba(var(--primary-rgb), 0.1);
  border-left-color: var(--complementary-color);
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin-bottom: 20px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Not Found */

.notFoundOverlay {
    display: flex;
    justify-content: center;
    align-items: center;
  }

.notFoundContent {
  background: white;
  padding: 40px;
  border-radius: 20px;
  text-align: center;
  animation: slideUp 0.5s ease-out forwards;
  max-width: 400px;
  width: 90%;
}

.notFoundIcon {
  font-size: 64px;
  margin-bottom: 20px;
  animation: pulse 2s infinite;
}

.notFoundContent h2 {
  color: var(--primary-color);
  font-size: 28px;
  margin: 0 0 10px;
  font-weight: 600;
}

.notFoundContent p {
  color: #666;
  font-size: 16px;
  margin: 0 0 20px;
}

.redirectMessage {
  color: #999;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.loadingDots {
  display: flex;
  gap: 2px;
}

.loadingDots span {
  animation: dots 1.4s infinite;
  opacity: 0;
}

.loadingDots span:nth-child(2) {
  animation-delay: 0.2s;
}

.loadingDots span:nth-child(3) {
  animation-delay: 0.4s;
}


@keyframes slideUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes dots {
  0%, 100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

.content {
  transition: all 0.5s ease;
}

.dashboard {
  display: flex;
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  z-index: 2;
  background: transparent;
}

.fullScreenContainer {
  position: absolute; /* Change from fixed to absolute */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  overflow: visible;
}


/* Scrollbar Styles */

.rightContentScroll {
  position: relative;
  flex: 1;
  overflow-y: auto;
  padding: 2.5rem;
  z-index: 2;
  background: linear-gradient(
    135deg,
    rgba(var(--analogous-1-rgb), 0.05) 0%,
    rgba(var(--analogous-2-rgb), 0.03) 100%
  );
  backdrop-filter: blur(8px);
  min-height: 100vh;
  width: 100%;
}

.leftPanel::-webkit-scrollbar,
.rightContentScroll::-webkit-scrollbar {
  width: 8px;
}

.leftPanel::-webkit-scrollbar-track,
.rightContentScroll::-webkit-scrollbar-track {
  background: transparent;
}

.leftPanel::-webkit-scrollbar-thumb,
.rightContentScroll::-webkit-scrollbar-thumb {
  background: var(--primary-gradient);
  border-radius: 4px;
  border: 2px solid transparent;
  background-clip: padding-box;
}

.leftPanel::-webkit-scrollbar-thumb:hover,
.rightContentScroll::-webkit-scrollbar-thumb:hover {
  background: var(--accent-gradient);
}



/* Left Panel */

.leftPanel {
  width: 400px;
  height: 100%;
  background: linear-gradient(
    135deg,
    var(--analogous-1) 0%,
    var(--analogous-2) 100%
  );
  padding: 2rem;
  overflow-y: auto;
  position: relative;
  backdrop-filter: blur(10px);
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
  transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
  animation: slideIn 0.6s cubic-bezier(0.4, 0, 0.2, 1);
  padding-top: 4rem; 
  z-index: 2; 
  border-right: 1px solid rgba(var(--primary-rgb), 0.2);
}

.leftPanel h1 {
  font-size: clamp(1rem, 5vw, 2rem);
  margin-bottom: 1rem;
  text-align: center;
  width: 100%;
  padding: 0 1rem;
  color: var(--left-panel-text);
  word-wrap: break-word;
  white-space: normal;
  line-height: 1.2;
}

.leftPanel p {
  font-size: 0.9rem; 
  line-height: 1.5;
  color: var(--left-panel-text-secondary);
  margin: 1rem 0;     
  padding: 0 0.5rem;
  text-align: left;  
}

.leftPanel p:first-of-type {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  text-align: center;
  font-size: 0.9rem;
  margin: 0.5rem 0;
  padding: 0;
  color: var(--left-panel-text-secondary);
}

.leftPanel h2 {
  color: var(--left-panel-text);
  margin: 1.5rem 0 1rem;
}

/* Left Panel Image */

.artistImageContainer {
  position: relative;
  width: 100%;
  padding-top: 100%;
  margin-bottom: 2rem;
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.1);
}

.artistImage {
  width: 100%;
  max-height: 300px;
  overflow: hidden;
  border-radius: 8px;
  margin-bottom: 20px;
  height: auto;
  margin: 2.5rem auto 2rem;
  position: relative;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0 8px 32px rgba(var(--primary-rgb), 0.15);
  animation: fadeIn 0.8s ease-out;
  z-index: 1;
}

.artistImage img {
  width: 100%;
  height: auto;
  height: 100%;
  object-fit: cover;
  object-position: center;
  transition: transform 0.3s ease;
}

.artistImage:hover img {
  transform: scale(1.05);

}

.hidden {
  display: none;
}

/* Artist Name Info  - Left Panel*/

.artistInfo h1 {
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 1rem;
  background: linear-gradient(45deg, white, rgba(255,255,255,0.8));
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: fadeSlideUp 0.6s ease forwards;
}

/* Artist Location Info  - Left Panel*/

.location {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 2rem;
  font-size: 1.1rem;
  opacity: 0;
  color: var(--left-panel-text-secondary);
  animation: fadeSlideUp 0.6s 0.2s ease forwards;
}

/* Artist Bio Info  - Left Panel*/

.truncatedBio, .expandedBio {
  font-size: 0.9rem;
  line-height: 1.5;
  color: var(--left-panel-text-secondary);
  margin: 1rem 0;
  padding: 0 0.5rem;
  text-align: left;
  cursor: pointer;
  transition: all 0.3s ease;
}

.truncatedBio {
  position: relative;
  max-height: 4.5em;
  overflow: hidden;
}

.truncatedBio::after {
  content: '...';
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 0 0.5rem;
  background: inherit;
}

.expandedBio {
  max-height: none;
}

/*Artist Tags Info  - Left Panel*/


.tagSection {
  margin-bottom: 2rem;
  opacity: 0;
  animation: fadeSlideUp 0.6s 0.4s ease forwards;
}

.tagSection h2 {
  font-size: 1.2rem;
  margin-bottom: 1rem;
  color: var(--left-panel-text);
}

.tagContainer {
  display: flex;
  flex-wrap: wrap;
  margin: 1rem 0 2rem;
  padding: 0.5rem;
  gap: 5px;
  margin-bottom: 15px;
}

.tag {
  padding: 0.8rem 1.4rem;
  background: linear-gradient(
    135deg,
    var(--analogous-1) 0%,
    var(--analogous-2) 100%
  );
  color: var(--left-panel-text);
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  border-radius: 25px;
  font-size: 0.9rem;
  font-weight: 500;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  position: relative;
  display: inline-flex;
  align-items: center;
  margin: 0.5rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
}

.tag:hover {
  background: var(--primary-color);
  color: white;
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(var(--primary-rgb), 0.2);
}

.tag:nth-child(odd) { --rotation: -1deg; }
.tag:nth-child(even) { --rotation: 1deg; }
.tag:nth-child(3n) { --rotation: -0.5deg; }
.tag:nth-child(3n+1) { --rotation: 0.5deg; }

.tag:hover {
  transform: translateY(-2px) rotate(0deg) scale(1.05);
  background: var(--primary-color);
  color: white;
  box-shadow: 0 4px 12px rgba(var(--primary-rgb), 0.2);
  border-color: var(--primary-color, #FF9F1C);
}

.tag:hover::before {
  opacity: 1;
  transform: scale(1.2);
}

@keyframes tagAppear {
  0% {
    opacity: 0;
    transform: translateY(10px) rotate(var(--rotation, 0deg));
  }
  100% {
    opacity: 1;
    transform: translateY(0) rotate(var(--rotation, 0deg));
  }
}

/* Left Panel - Spotify Stats */



.musicStatsContainer {
  margin: 2rem 0;
}

.musicStatsContainer h2 {
  margin-bottom: 1rem;
  color: var(--left-panel-text);
}

.musicStats {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
}

.statCard {
  background: var(--card-bg, rgba(255, 255, 255, 0.1));
  padding: 1rem;
  border-radius: 12px;
  text-align: center;
  backdrop-filter: blur(5px);
  transition: transform 0.3s ease;
  border: 1px solid rgba(var(--primary-rgb), 0.1);
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.06);
}

.statCard:hover {
  transform: translateY(-4px);
  border-color: var(--primary-color);
  box-shadow: 0 8px 30px rgba(var(--complementary-rgb), 0.15);
}

.statCard h3 {
  font-size: 0.9rem;
  color: var(--left-panel-text);
  margin-bottom: 0.5rem;
}

.statCard p {
  font-size: 1.2rem;
  font-weight: 600;
  color: var(--left-panel-text);
  margin: 0;
}

/* Navigation Links  - Left Panel*/



.buttonContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
}

.buttonContainer button {
  padding: 1.2rem 1.8rem;
  border: none;
  border-radius: 16px;
  background: var(--primary-gradient);
  color: var(--left-panel-text); 
  font-weight: 600;
  font-size: 1.1rem;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  display: flex;
  align-items: center;
  gap: 1rem;
  position: relative;
  overflow: hidden;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);  
}

.buttonContainer button::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(135deg, 
    rgba(var(--complementary-rgb), 0.8),
    rgba(var(--triadic-1-rgb), 0.8)
  );
  opacity: 0;
  transition: opacity 0.3s ease;
}

.buttonContainer button:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 20px rgba(var(--primary-color), 0.2);
  background: var(--complementary-color);
  color: var(--text-light); 
}

.buttonContainer button:hover::before {
  opacity: 1;
}

.buttonContainer button svg {
  position: relative;
  z-index: 1;
  color: var(--left-panel-text);
}

.buttonContainer button span {
  position: relative;
  z-index: 1;
}

/* Right Panel */

.warning {
  font-size: 0.8rem;
  color: red;
  text-align: center;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
}


.topRow,
.middleRow {
  margin-bottom: 20px;
  position: relative;
  z-index: 2;
}

.bottomRow p {
  margin-bottom: 5px;
}

.topRow {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

.middleRow h2,
.bottomRow h2 {
  color: var(--left-panel-text);
  border-bottom: 2px solid var(--left-panel-text);
  padding-bottom: 5px;
}

.middleRow, .bottomRow {
  margin-bottom: 3rem;
}


.rightContentScroll h2 {
  font-size: 1.8rem; 
  color: var(--left-panel-text);
  margin-bottom: 1.5rem;
  padding-bottom: 1rem;
  position: relative;
  display: flex;
  align-items: center;
  gap: 1rem;
  border-bottom: 2px solid var(--primary-color, #FF9F1C);
}


.rightContent {
  position: relative;
  flex: 1;
  height: 100vh;
  display: flex;
  flex-direction: column;
  z-index: 1;
  overflow: hidden;
  backdrop-filter: blur(10px);
  border-left: 1px solid rgba(var(--primary-rgb), 0.1);
  background: linear-gradient(
    135deg,
    rgba(var(--primary-rgb), 0.02) 0%,
    rgba(var(--complementary-rgb), 0.01) 100%
  );
}

/* Section Styles, for each Top Artists on right panel */
.section {
  background: linear-gradient(
    135deg,
    rgba(var(--primary-rgb), 0.05) 0%,
    rgba(var(--complementary-rgb), 0.02) 100%
  );
  border-radius: 24px;
  padding: 1.5rem; 
  position: relative;
  border: 1px solid rgba(var(--primary-rgb), 0.1);
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  margin-top: 15px;  
  backdrop-filter: blur(8px);
  z-index: 2;
  margin-bottom: 3rem;  
}

.section::before {
  display: none;
}

.section:hover {
  transform: translateY(-2px);
  background: linear-gradient(
    135deg,
    rgba(var(--primary-rgb), 0.08) 0%,
    rgba(var(--complementary-rgb), 0.04) 100%
  );
}

.sectionTitle {
  font-size: 1.8rem;
  color: var(--left-panel-text);
  margin-bottom: 2rem;
  padding-bottom: 1rem;
  position: relative;
  display: flex;
  align-items: center;
  gap: 1rem;
  border-bottom: 2px solid var(--analogous-1);
}

.sectionTitle::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background: linear-gradient(
    90deg,
    rgba(var(--primary-rgb, 255, 159, 28), 0.2),
    transparent
  );
}

/* Top sections - Unique Selling Point, & Key Talents. */

.expandableSection {
  background: linear-gradient(
    135deg,
    rgba(var(--primary-rgb), 0.04) 0%,
    rgba(var(--analogous-2-rgb), 0.02) 100%
  );
  border: 1px solid rgba(var(--primary-rgb), 0.1);
  border-radius: 20px;
  background-color: white;
  padding: 2rem;
  height: 100%;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  backdrop-filter: blur(8px);
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.06);
  color: var(--text-dark);
}

.expandableSection:hover {
  background: rgba(255, 255, 255, 0.95);
  border-color: var(--primary-color);
  box-shadow: 0 8px 30px rgba(var(--complementary-rgb), 0.15);
  transform: translateY(-4px);
}

.expandableSection h2 {
  color: #000000 !important;

  border-bottom: 2px solid var(--analogous-1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 0;
  padding-bottom: 10px;
  margin-bottom: 15px;
}

.expandableSection h2::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background: linear-gradient(
    90deg,
    rgba(var(--left-panel-text)),
    transparent
  );
}

.expandableSection ul {
  color: var(--text-dark); 
}

.expandableSection li {
  color: var(--text-dark);
}

.expandableSection strong {
  color: var(--text-dark); 
  opacity: 0.9;
}

/* Bottom sections - Target Audience, & Core Values */

.card {
  background: linear-gradient(
    135deg,
    rgba(var(--primary-rgb), 0.03) 0%,
    rgba(var(--analogous-1-rgb), 0.02) 100%
  );
  background-color: white;
  border-radius: 16px;
  padding: 1.8rem;
  border-left: 4px solid rgba(var(--primary-rgb), 0.2);
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  position: relative;
  overflow: hidden;
  backdrop-filter: blur(8px);
  z-index: 2;
  box-shadow: 0 4px 15px rgba(var(--primary-rgb), 0.06);
  border: 1px solid rgba(var(--primary-rgb), 0.1);
}

.card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 4px;
  height: 100%;
  background: linear-gradient(
    180deg,
    var(--primary-color),
    var(--secondary-color)
  );
  opacity: 0.8;
}

.card:hover {
  background: rgba(255, 255, 255, 0.95);
  border-color: var(--primary-color);
  box-shadow: 0 8px 30px rgba(var(--complementary-rgb), 0.15);
  transform: translateY(-4px);
}

.audience {
  padding-top: 0%;
  text-align: center;
  font-size: 100%;
}

.scrollableCards {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 1.5rem;
  padding: 0.5rem;
  margin-bottom: 1.5rem; 
}

.scrollableCards {
  display: flex;
  overflow-x: auto;
  gap: 15px;
  padding-top: 1%;
}

.scrollableCards h3 {
  text-align: center;
}

.scrollableCards p {
  padding-top: 5%;
  text-align: center;
  font-size: 100%;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.coreValuesGrid {
  padding-bottom: 20px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 15px;
  text-align: center;
}

.coreValuesGrid h2 {
  padding-bottom: 20px;
}

.coreValuesGrid h3 {
  padding-bottom: 1rem;
}

li {
  margin-top: 10px;
  list-style-type: none;
}


/* Top Tracks Section */

.trackStats {
  display: flex;
  align-items: center;
  gap: 1rem;
  color: var(--text-secondary, #666);
  font-size: 0.9rem;
}


.tracksListContainer {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;  
  margin-top: 0;  
}

.tracksList {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;  
  padding: 0;
  margin: 0;
  max-height: 400px;  
  overflow-y: auto;
}

.trackItem {
  cursor: pointer;
  padding: 0.75rem 1rem;
  margin: 0;
  border-radius: 10px; 
  background: linear-gradient(
    135deg,
    rgba(255, 255, 255, 0.95) 0%,
    rgba(255, 255, 255, 0.98) 100%
  );
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  border: 1px solid rgba(var(--primary-rgb), 0.15);
  position: relative;
  overflow: hidden;
  backdrop-filter: blur(8px);
}

.trackItem:hover {
  background: linear-gradient(
    135deg,
    rgba(255, 255, 255, 0.98) 0%,
    rgba(255, 255, 255, 1) 100%
  );
  border-color: var(--primary-color);
  box-shadow: 0 8px 30px rgba(var(--primary-color), 0.25);
  transform: translateY(-2px) translateX(4px);
}

.trackItem:hover .trackNumber {
  color: var(--complementary-color);
  opacity: 1;
}

.trackItem:hover .trackTitles h3 {
  color: var(--complementary-color);
}

.trackItem::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 4px;
  height: 100%;
  background: var(--primary-gradient);
  opacity: 0.7;
  transition: all 0.3s ease;
}

.trackItem:hover::before {
  width: 100%;
  opacity: 0.15;
}

.trackMainInfo {
  display: flex;
  align-items: center;
  gap: 1rem;
  min-width: 0;
}

.trackTitles {
  min-width: 0;
  overflow: hidden;
}

.trackTitles h3 {
  margin: 0;
  font-size: 0.9rem;
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.trackTitles p {
  margin: 0.2rem 0 0;
  font-size: 0.8rem;
  color: var(--text-secondary);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.trackMetadata {
  display: flex;
  gap: 1rem;
  font-size: 0.8rem;
  color: var(--text-secondary);
}

.trackNumber {
  font-size: 0.9rem;
  font-weight: 600;
  color: var(--primary-color);
  opacity: 0.7;
  min-width: 24px;
}

/* Popularity Status */

.popularityStatus {
  background: linear-gradient(135deg, 
    rgba(var(--primary-rgb), 0.08) 0%,
    rgba(var(--left-panel-text), 0.05) 100%
  );
  border-radius: 16px;
  padding: 1rem;
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.15);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  transition: all 0.3s ease;
}

.statusIconWrapper {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
}

.mainIcon {
  font-size: 2rem;
  position: relative;
  z-index: 2;
  filter: drop-shadow(0 0 10px rgba(var(--primary-rgb), 0.5));
  animation: floatMain 3s ease-in-out infinite;
}

.supportIcon {
  font-size: 1.2rem;
  position: absolute;
  top: -5px;
  right: -8px;
  opacity: 0.9;
  animation: floatSupport 3s ease-in-out infinite;
}

.statusText {
  font-size: 1rem;
  font-weight: 800;
  text-align: center;
  background: linear-gradient(135deg, 
    var(--complementary-color), 
    var(--left-panel-text)
  );
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.levelProgress {
  display: flex;
  gap: 3px;
  padding: 0.3rem;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 12px;
}

.levelDot {
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.2);
  transition: all 0.3s ease;
}

.levelDot.active {
  background: var(--primary-color);
  box-shadow: 0 0 5px var(--primary-color);
  animation: dotPulse 2s infinite;
}

@keyframes floatMain {
  0%, 100% { transform: translateY(0); }
  50% { transform: translateY(-10px); }
}

@keyframes floatSupport {
  0%, 100% { transform: translate(0); }
  50% { transform: translate(5px, -5px); }
}

@keyframes dotPulse {
  0% { transform: scale(1); opacity: 1; }
  50% { transform: scale(1.3); opacity: 0.7; }
  100% { transform: scale(1); opacity: 1; }
}

/* Mobile Styles */


.topButtons {
  display: none;
}


.visuallyHidden {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

@keyframes slideIn {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}



@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0) translateX(-50%);
  }
  40% {
    transform: translateY(-10px) translateX(-50%);
  }
  60% {
    transform: translateY(-5px) translateX(-50%);
  }
}

.leftPanelIndicator {
  display: none;
}

.mobileArtistImage {
  display: none;
}



@media screen and (max-width: 768px) {

  .fullScreenContainer {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: visible;
  }

  .dashboard {
    min-height: 100vh;
    height: auto;
    overflow: visible;
  }

  .rightContent {
    position: relative !important;
    min-height: 100vh;
    height: auto !important;
    overflow: visible !important;
  }

  /* Force visibility of mobile sections */
  .mobileSection,
  .rightContentScroll,
  .expandableSection,
  .scrollableCards,
  .card,
  .tracksList,
  .trackItem {
    opacity: 1 !important;
    visibility: visible !important;
    transform: none !important;
    transition: none !important;
  }

  .fullScreenContainer {
    position: fixed;
    width: 100vw;
    height: 100vh;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
  }

  .rightContent {
    position: relative !important;
    min-height: 100vh;
    height: auto !important;
    overflow: visible !important;
  }

  /* Left Panel */
  .leftPanel {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: auto;
    max-height: 85vh;
    transform: translateY(calc(100% - 120px));
    transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    border-radius: 20px 20px 0 0;
    padding: 1rem;
    padding-top: 4rem;
    z-index: 1000;
    background: linear-gradient(
      135deg,
      var(--analogous-1) 0%,
      var(--analogous-2) 100%
    );
  }

  .leftPanel.expanded {
    transform: translateY(0);
    overflow-y: auto;
  }


  /* Not Found */

  .notFoundContent {
    padding: 30px;
    width: 85%;
  }

  .notFoundIcon {
    font-size: 48px;
  }

  .notFoundContent h2 {
    font-size: 24px;
  }

  .notFoundContent p {
    font-size: 14px;
  }



  /* Mobile Buttons on Top left panel */

  .topRow {
    display: block;
  }

  .topButtons {
    display: flex;
    justify-content: space-between;
    position: absolute;
    top: 20px;
    left: 20px;
    right: 20px;
    z-index: 1000;
    pointer-events: auto;
  }

  .topButton {
    background: var(--primary-gradient);
    color: var(--text-light);
    border: none;
    width: 70px;
    height: 70px;
    border-radius: 35px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.3s ease;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(10px);
  }

  .topButton:hover, 
  .topButton:active {
    transform: translateY(-2px);
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.25);
  }

  .topButton svg {
    margin-bottom: 4px;
    color: white;
  }

  .topButton span {
    font-size: 12px;
    color: white;
    font-weight: 500;
  }

  .topButtons.hidden {
    opacity: 0;
    pointer-events: none;
    transform: translateY(20px);
    transition: all 0.3s ease;
  }


  .leftPanel {
    padding-top: 60px; 
  }

  /* Left Panel Drag, indicator  */
  .leftPanelIndicator {
    display: block;
    position: absolute;
    top: 0.5rem; 
    left: 50%;
    transform: translateX(-50%);
    color: rgba(255, 255, 255, 0.7);
    cursor: pointer;
    z-index: 2; 
    background: rgba(255, 255, 255, 0.1);
    padding: 0.5rem;
    border-radius: 1rem;
    backdrop-filter: blur(5px);
  }



  /* Left Panel Profile Image */
  .leftPanel .artistImage {
    display: none;
  }

  .mobileArtistImage {
    display: block;
    position: relative;
    width: 120px;
    height: 120px;
    margin: 15px auto 20px; 
    border-radius: 50%;
    overflow: hidden;
    border: 3px solid var(--primary-color, #fff);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  }

  .mobileArtistImage img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  /* Left Panel Artist Name */

  .leftPanel h1 {
    font-size: 1.2rem;
    margin: 0.1rem auto;  
    padding: 0;
    text-align: center;
    width: 100%;  
    max-width: 280px;  
    position: relative; 
    left: 0;
  }

  /* Location Tag */
  .location {
    margin: 0 0 1rem 80px;
  }



  /* Right Panel  (Should be on Top)*/


  .rightContent {
    width: 100%;
    height: 100vh;
    padding: 0;
    overflow-y: auto;
    scroll-snap-type: y mandatory;
    background: linear-gradient(
      135deg,
      rgba(var(--analogous-1-rgb), 0.02) 0%,
      rgba(var(--analogous-2-rgb), 0.01) 100%
    );
    border-radius: 0;
    order: 1;
  }

  .rightContentScroll {
    scroll-snap-type: y mandatory;
    -webkit-overflow-scrolling: touch;
    height: 100vh;
    overflow-y: auto;
    padding: 1rem;
    padding-bottom: 120px; 
    opacity: 1;
    visibility: visible;
    transition: opacity 0.3s ease-in-out;
  }

  .mobileSection {
    min-height: 100vh;
    scroll-snap-align: start;
    scroll-snap-stop: always;
    padding: 2rem 1rem;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);
    opacity: 0.5;
    transform: scale(0.95);
  }

  .mobileSection.active {
    opacity: 1;
    transform: scale(1);
  }

  /* Enhanced Section Navigation Dots */
  .sectionIndicator {
    position: fixed;
    right: 1rem;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
    z-index: 1000;
    background: rgba(var(--primary-rgb), 0.1);
    padding: 0.8rem 0.4rem;
    border-radius: 2rem;
    backdrop-filter: blur(8px);
  }

  .sectionDot {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: rgba(var(--primary-rgb), 0.3);
    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    cursor: pointer;
    position: relative;
  }

  .sectionDot::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border: 2px solid transparent;
    transition: all 0.3s ease;
  }

  .sectionDot.active {
    background: var(--primary-color);
    transform: scale(1.2);
  }

  .sectionDot.active::before {
    border-color: rgba(var(--primary-rgb), 0.3);
  }

  /* Enhanced Scroll Prompt */
  .scrollPrompt {
    position: absolute;
    bottom: 2rem;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
    opacity: 0;
    animation: fadeInOut 3s infinite;
    pointer-events: none;
  }

  .scrollPrompt svg {
    color: var(--primary-color);
    animation: floatDown 2s infinite;
  }

  .scrollPrompt::after {
    content: 'Scroll to explore';
    font-size: 0.8rem;
    color: var(--primary-color);
    font-weight: 500;
  }

 
  .sectionTitle {
    font-size: 2rem;
    margin-bottom: 2rem;
    opacity: 0;
    transform: translateY(20px);
    transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);
    text-align: center;
    background: var(--left-panel-text);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    position: relative !important;
  }

  .middleRow .topRow h2 {
    opacity: 0;
    transition: opacity 0.5s ease;
  }

  .mobileSection.active .sectionTitle {
    opacity: 1;
    transform: translateY(0);
  }

  /* Enhanced Animations */
  @keyframes fadeInOut {
    0%, 100% { opacity: 0; }
    50% { opacity: 1; }
  }

  @keyframes floatDown {
    0%, 100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(10px);
    }
  }

  /* Gesture Hint Animation */
  .gestureHint {
    position: fixed !important;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background: rgba(var(--primary-rgb), 0.2);
    pointer-events: none;
    opacity: 0;
    animation: gestureHint 2s ease-out;
  }

  @keyframes gestureHint {
    0% {
      transform: translate(-50%, -50%) scale(0);
      opacity: 1;
    }
    100% {
      transform: translate(-50%, -50%) scale(2);
      opacity: 0;
    }
  }

  /* Section Content Transitions */
  .mobileSection > * {
    opacity: 0;
    transform: translateY(20px);
    transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);
    transition-delay: 0.2s;
  }

  .mobileSection.active > * {
    opacity: 1;
    transform: translateY(0);
  }

  /*  Card Layouts for Mobile */
  .scrollableCards {
    display: flex;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    gap: 1rem;
    padding: 1rem 0;
    margin: 0 -1rem;
    -webkit-overflow-scrolling: touch;
  }

  .card {
    min-width: 280px;
    scroll-snap-align: start;
    margin: 0 1rem;
    transform: scale(0.95);
    transition: all 0.3s ease;
  }

  .card:first-child {
    margin-left: 1rem;
  }

  .card:last-child {
    margin-right: 1rem;
  }

  .card.active {
    transform: scale(1);
  }

  /* Track List Mobile Optimization */
  .tracksListContainer {
    display: flex !important;;
    flex-direction: column;
    gap: 1rem;
  }

  .tracksList {
    max-height: none;
  }

  .trackItem {
    transform: scale(0.95);
    transition: all 0.3s ease;
  }

  .trackItem.active {
    transform: scale(1);
  }
}