.profile {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 0.5rem 0.75rem 0.5rem 1.25rem;
  background: rgba(255, 255, 255, 0.95);
  border-radius: 50px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(8px);
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.profile:hover {
  transform: translateY(-1px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  background: rgba(255, 255, 255, 0.98);
}

.profile__info {
  display: flex;
  align-items: center;
  gap: 0.75rem;
}

.profile__name {
  font-weight: 500;
  color: #4a3a1f;
  font-size: 0.95rem;
}

.profile__picture {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.profile__picture:hover {
  transform: scale(1.05);
}

.profile__icon {
  width: 32px;
  height: 32px;
  color: #4a3a1f;
  opacity: 0.8;
}

.profile__logout {
  padding: 0.5rem;
  border: none;
  background: none;
  color: #4a3a1f;
  cursor: pointer;
  opacity: 0.6;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-left: 0.25rem;
}

.profile__logout:hover {
  opacity: 1;
  background: rgba(229, 62, 62, 0.1);
  color: #e53e3e;
  transform: scale(1.1);
}


@media (max-width: 768px) {
  .profile {
    padding: 0.4rem 0.6rem 0.4rem 1rem;
  }

  .profile__name {
    font-size: 0.9rem;
  }

  .profile__picture,
  .profile__icon {
    width: 28px;
    height: 28px;
  }
}

@media (max-width: 480px) {
  .profile__name {
    display: none;
  }

  .profile {
    padding: 0.4rem;
  }
}