.login__page {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  padding-top: 40px;
}

.login__container {
  background: rgba(255, 255, 255, 0.95);
  border-radius: 20px;
  width: min(98%, 630px);
  position: relative;
  z-index: 1;
}

.login__content {
  text-align: center;
  margin-bottom: 0;
}

.login__card {
  background: rgba(255, 255, 255, 0.98);
  border-radius: 20px;
  margin-top: 15px;
  transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
  min-height: 320px;
  position: relative;
  padding: 0.45rem;
}

.login__logo {
  width: 100%;
  height: auto;
  margin: 0 auto 0.5rem;
  display: block;
  filter: drop-shadow(0 4px 8px rgba(0, 0, 0, 0.1));
}

.login__title {
  font-size: 2.25rem;
  font-weight: 700;
  color: #4a3a1f;
  margin-bottom: 0.5rem;
  background: linear-gradient(45deg, #ffaa00, #e53540);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.login__subtitle {
  color: #5a4a2f;
  font-size: 0.99rem;
  margin-bottom: 0.75rem;
  opacity: 0.8;
}

.login__input_wrapper {
  position: relative;
  margin-bottom: 1.5rem;
  width: 100%;
  display: flex;
  align-items: center;
}

.login__input_icon {
  position: absolute;
  left: 1rem;
  top: 50%;
  transform: translateY(-50%);
  color: #5a4a2f;
  transition: all 0.3s ease;
  pointer-events: none;
}

.login__input {
  width: 100%;
  padding: 1rem 1rem 1rem 3rem;
  border: 2px solid rgba(90, 74, 47, 0.2);
  border-radius: 12px;
  font-size: 1rem;
  color: #4a3a1f;
  transition: all 0.3s ease;
  background: rgba(255, 255, 255, 0.9);
  padding-right: 3rem;
}

.login__input:focus {
  outline: none;
  border-color: #ffaa00;
  box-shadow: 0 0 0 3px rgba(255, 170, 0, 0.1);
}

.login__input:focus + .login__input_icon {
  color: #ffaa00;
}

.login__error-message {
  color: #e53540;
  font-size: 0.85rem;
  margin-top: 0.5rem;
  display: block;
}

.login__button_group {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1.5rem;
  margin: 2rem 0;
  width: 100%;
}

.login__search-button, 
.login__inspire-button {
  flex: 1;
  padding: 1rem;
  border: none;
  border-radius: 12px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
}

.login__search-button {
  background: #e53540;
  color: white;
}

.login__inspire-button {
  background: #ffaa00;
  color: white;
}

.login__search-button:hover,
.login__inspire-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.login__back-button {
  width: 100%;
  padding: 1rem;
  margin-top: 1rem;
  background: transparent;
  border: 2px solid rgba(90, 74, 47, 0.2);
  border-radius: 12px;
  color: #5a4a2f;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}

.login__back-button:hover {
  background: rgba(90, 74, 47, 0.05);
  border-color: #5a4a2f;
}

.login__button_primary,
.login__button_secondary,
.login__button_trial {
  width: 100%;
  padding: 1rem 2rem;
  border: none;
  border-radius: 12px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  position: relative;
  overflow: hidden;
}

.login__button_primary {
  background: linear-gradient(45deg, #e53540, #ff6b6b);
  color: white;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  font-weight: 600;
  min-height: 48px;
}

.login__button_primary:not(.login__button_disabled):hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(229, 53, 64, 0.2);
}

.login__button_secondary {
  background: linear-gradient(45deg, #ffaa00, #ffcc00);
  color: white;
}

.login__button_trial {
  width: 100%;
  padding: 1rem;
  margin-top: 1.5rem;
  background: transparent;
  border: 2px solid rgba(90, 74, 47, 0.2);
  border-radius: 12px;
  color: #5a4a2f;
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  cursor: pointer;
  transition: all 0.3s ease;
}

.login__button_primary:hover,
.login__button_secondary:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.login__button_trial:hover {
  background: rgba(255, 170, 0, 0.05);
  border-color: #ffaa00;
}

.role-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 1rem;
  margin: 2rem 0;
}

.role-card {
  background: white;
  border: 2px solid rgba(90, 74, 47, 0.1);
  padding: 1.5rem;
  border-radius: 16px;
  cursor: pointer;
  transition: all 0.3s ease;
  text-align: left;
}

.role-card:hover {
  transform: translateY(-5px);
  border-color: #ffaa00;
  box-shadow: 0 10px 20px rgba(255, 170, 0, 0.1);
}

.role-icon {
  color: #ffaa00;
  margin-bottom: 1rem;
}

.role-title {
  font-size: 1.2rem;
  color: #4a3a1f;
  margin-bottom: 0.5rem;
}

.role-description {
  font-size: 0.9rem;
  color: #5a4a2f;
  opacity: 0.8;
}

.trial-container {
  display: grid;
  gap: 0.75rem;
  margin: 1.5rem 0;
  padding: 0.5rem;
}

.trial-artist {
  width: 100%;
  padding: 1rem 1.5rem;
  background: white;
  border: 2px solid rgba(90, 74, 47, 0.1);
  border-radius: 12px;
  text-align: left;
  font-size: 1.1rem;
  color: #4a3a1f;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
}

.trial-artist::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(45deg, #ffaa00, #e53540);
  opacity: 0;
  transition: opacity 0.3s ease;
  z-index: 1;
}

.trial-artist:hover {
  transform: translateY(-2px);
  border-color: #ffaa00;
  box-shadow: 0 4px 12px rgba(255, 170, 0, 0.1);
}

.trial-artist:hover::before {
  opacity: 0.05;
}

@media (max-width: 768px) {
  .login__container {
    width: 98%;
    margin: 0.9rem;
  }

  .login__logo {
    width: 150px;
  }

  .login__card {
    padding: 0.5rem;
  }

  .login__page {
    padding: 15px;
  }

  .login__view {
    padding: 2.25rem 0.75rem 0.75rem;
  }

  .login__buttons_container,
  .login__cta_container {
    padding: 0 0.75rem;
  }

  .login__welcome {
    font-size: 2.52rem;
  }
  
  .login__button_main_text {
    font-size: 0.99rem;
  }
}

@media (max-width: 480px) {
  .login__card {
    padding: 1.5rem;
  }

  .login__title {
    font-size: 2rem;
  }

  .role-grid {
    grid-template-columns: 1fr;
  }

  .login__button_group {
    grid-template-columns: 1fr;
    gap: 1rem;
  }

  .login__button-group {
    flex-direction: column;
  }

  .login__view {
    padding: 2rem 0.75rem 0.75rem;
  }
}

.login__role_grid {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  margin: 2rem 0;
  width: 100%;
}

.login__role_card {
  flex: 1;
  background: white;
  border: 2px solid rgba(90, 74, 47, 0.1);
  padding: 1.125rem 0.9rem;
  border-radius: 16px;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 108px;
  position: relative;
  overflow: hidden;
  animation: fadeInScale 0.4s ease;
  transform-origin: center;
  min-height: 162px;
  padding: 0.7875rem;
  gap: 0.675rem;
}

.login__role_card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(45deg, rgba(255, 170, 0, 0.1), rgba(229, 53, 64, 0.1));
  opacity: 0;
  transition: opacity 0.3s ease;
}

.login__role_card:hover {
  transform: translateY(-2px);
  border-color: rgba(255, 170, 0, 0.5);
}

.login__role_card:hover::before {
  opacity: 0.5;
}

.login__role_icon_wrapper {
  position: relative;
  width: 54px;
  height: 54px;
  border-radius: 14px;
  background: linear-gradient(135deg, rgba(255, 170, 0, 0.1), rgba(229, 53, 64, 0.1));
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
}

.login__role_icon {
  color: #ffaa00;
  margin-bottom: 1rem;
  transition: all 0.3s ease;
  font-size: 2rem;
  color: #ffaa00;
  transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
  filter: drop-shadow(0 2px 4px rgba(255, 170, 0, 0.2));
}

.login__role_card:hover .login__role_icon {
  transform: scale(1.1);
  color: #e53540;
}

.login__role_title {
  font-size: 1.1rem;
  color: #4a3a1f;
  margin-bottom: 0.5rem;
  font-weight: 600;
}

.login__role_card h3 {
  font-size: 1.35rem;
  font-size: 1.5rem;
  font-weight: 700;
  color: #4a3a1f;
  margin: 0;
  transition: all 0.3s ease;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.login__role_description {
  font-size: 0.85rem;
  color: #5a4a2f;
  opacity: 0.8;
}

.login__role_card p {
  font-size: 0.9rem;
  font-size: 1rem;
  color: #5a4a2f;
  opacity: 0.8;
  margin: 0;
  line-height: 1.6;
}

.login__role_card_selected {
  border-color: #ffaa00;
  background: linear-gradient(45deg, rgba(255, 170, 0, 0.1), rgba(229, 53, 64, 0.1));
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(255, 170, 0, 0.15);
  animation: selectedRole 0.4s ease forwards;
}

.login__role_card_selected .login__role_icon {
  color: #e53540;
  transform: scale(1.1);
}

.login__role_card_selected .login__role_title {
  color: #e53540;
}

.login__role_checkbox {
  position: absolute;
  top: 1rem;
  right: 1rem;
  width: 24px;
  height: 24px;
  border: 2px solid rgba(90, 74, 47, 0.2);
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
}

.login__role_card_selected .login__role_checkbox {
  background: #ffaa00;
  border-color: #ffaa00;
  color: white;
}

.login__button_disabled {
  opacity: 0.5;
  cursor: not-allowed;
  pointer-events: none;
  background: #ccc;
  transform: none !important;
  box-shadow: none !important;
}

.login__role_container {
  margin-bottom: 1rem;
}

.login__selected_roles {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-top: 0.5rem;
  padding: 0.375rem;
}

.login__selected_role_tag {
  background: rgba(255, 170, 0, 0.1);
  color: #ffaa00;
  padding: 0.25rem 0.75rem;
  border-radius: 1rem;
  font-size: 0.875rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

@media (max-width: 768px) {
  .login__role_grid {
    flex-direction: column;
  }

  .login__role_card {
    width: 100%;
  }
}

.login__button_back {
  width: 100%;
  padding: 1rem;
  margin-top: 1rem;
  background: transparent;
  border: 2px solid rgba(255, 170, 0, 0.3);
  border-radius: 12px;
  color: #4a3a1f;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  position: relative;
  overflow: hidden;
}

.login__button_back::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    45deg,
    rgba(255, 170, 0, 0.1),
    rgba(229, 53, 64, 0.1)
  );
  transform: translateX(-100%);
  transition: transform 0.4s cubic-bezier(0.4, 0, 0.2, 1);
}

.login__button_back:hover {
  border-color: #ffaa00;
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(255, 170, 0, 0.15);
}

.login__button_back:hover::before {
  transform: translateX(0);
}

.login__button_back:active {
  transform: translateY(1px);
  box-shadow: 0 2px 6px rgba(255, 170, 0, 0.1);
}

.login__button_back:disabled {
  opacity: 0.6;
  cursor: not-allowed;
  transform: none;
  box-shadow: none;
}

.login__button_back:disabled::before {
  display: none;
}

.login__trial_container {
  display: flex;
  flex-wrap: wrap;
  gap: 0.375rem;
  margin: 0.75rem 0 0.375rem;
  width: 100%;
  justify-content: center;
  padding: 0.25rem;
}

.login__trial_artist {
  flex: 0 1 auto;
  padding: 0.8rem 1.4rem;
  background: white;
  border: 2px solid rgba(90, 74, 47, 0.1);
  border-radius: 25px;
  font-size: 1rem;
  font-weight: 500;
  color: #4a3a1f;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  position: relative;
  display: inline-flex;
  align-items: center;
  margin: 0.25rem;
  transform: rotate(var(--rotation, 0deg));
}

.login__trial_artist:nth-child(1) { --rotation: -2deg; }
.login__trial_artist:nth-child(2) { --rotation: 1deg; }
.login__trial_artist:nth-child(3) { --rotation: -1deg; }
.login__trial_artist:nth-child(4) { --rotation: 2deg; }

.login__trial_artist::before {
  content: '🎵';
  margin-right: 8px;
  font-size: 1em;
  opacity: 0.7;
  transition: all 0.3s ease;
}

.login__trial_artist:hover {
  background: linear-gradient(
    45deg,
    rgba(255, 170, 0, 0.05),
    rgba(229, 53, 64, 0.05)
  );
  border-color: #ffaa00;
  transform: translateY(-5px) rotate(0deg) scale(1.05);
  box-shadow: 0 10px 20px rgba(255, 170, 0, 0.15);
  z-index: 1;
}

.login__trial_artist:hover::before {
  opacity: 1;
  transform: scale(1.1);
}

.login__trial_artist:active {
  transform: translateY(2px) rotate(0deg);
  box-shadow: 0 2px 10px rgba(255, 170, 0, 0.1);
}

@media (max-width: 480px) {
  .login__trial_container {
    padding: 0.5rem;
  }
  
  .login__trial_artist {
    padding: 0.6rem 1rem;
    font-size: 0.9rem;
    margin: 0.3rem;
  }
}

@keyframes floatIn {
  0% {
    opacity: 0;
    transform: translateY(20px) rotate(var(--rotation, 0deg));
  }
  100% {
    opacity: 1;
    transform: translateY(0) rotate(var(--rotation, 0deg));
  }
}

.login__welcome {
  font-size: 2.52rem;
  line-height: 1.1;
  margin-bottom: 0.75rem;
  background: linear-gradient(135deg, #4a3a1f, #000);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.login__options {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
}

.login__option {
  background: white;
  border: 2px solid rgba(255, 170, 0, 0.2);
  border-radius: 16px;
  padding: 1.5rem;
  text-align: left;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  cursor: pointer;
}

.login__option h3 {
  font-size: 1.4rem;
  margin-bottom: 0.5rem;
  color: #4a3a1f;
}

.login__option p {
  color: #5a4a2f;
  opacity: 0.8;
}

.login__option:hover {
  transform: translateY(-2px);
  border-color: #ffaa00;
  box-shadow: 0 8px 24px rgba(255, 170, 0, 0.15);
}

.login__option_signin {
  background: linear-gradient(45deg, rgba(255, 170, 0, 0.05), rgba(229, 53, 64, 0.05));
}

.login__option_register {
  background: linear-gradient(45deg, rgba(229, 53, 64, 0.05), rgba(255, 170, 0, 0.05));
}

.login__try_section {
  text-align: center;
  margin-top: 2rem;
  padding-top: 2rem;
  border-top: 2px solid rgba(255, 170, 0, 0.1);
}

.login__try_section h3 {
  font-size: 1.2rem;
  color: #4a3a1f;
  margin-bottom: 0.5rem;
}

.login__try_section p {
  color: #5a4a2f;
  opacity: 0.8;
  margin-bottom: 1.5rem;
}

.login__back_button {
  position: absolute;
  top: 1.25rem;
  left: 1.25rem;
  background: rgba(0, 0, 0, 0.85);
  border: none;
  color: white;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
  padding: 0.6rem 1rem;
  border-radius: 10px;
  font-size: 0.9rem;
  font-weight: 500;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  z-index: 10;
  transform-origin: center;
  opacity: 0;
  animation: fadeInScale 0.3s ease forwards;
}

.login__back_button:hover {
  background: rgba(0, 0, 0, 0.95);
  transform: translateY(-1px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

.login__back_button:active {
  transform: translateY(1px);
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
}

.login__back_button svg {
  width: 16px;
  height: 16px;
  stroke-width: 2.5;
}

.login__view {
  position: relative;
  padding: 1.75rem 0.75rem 0.75rem;
  opacity: 0;
  transform: translateY(20px);
  animation: slideIn 0.4s ease forwards;
}

@media (max-width: 768px) {
  .login__back_button {
    top: 1rem;
    left: 1rem;
    padding: 0.5rem 0.875rem;
    font-size: 0.85rem;
  }
  
  .login__view {
    padding: 2.25rem 0.75rem 0.75rem;
  }
}

@media (max-width: 480px) {
  .login__back_button {
    top: 0.875rem;
    left: 0.875rem;
    padding: 0.45rem 0.75rem;
    font-size: 0.8rem;
  }
  
  .login__view {
    padding: 2rem 0.75rem 0.75rem;
  }
}

.login__view {
  position: relative;
  padding: 1.75rem 0.75rem 0.75rem;
  opacity: 0;
  transform: translateY(20px);
  animation: slideIn 0.4s ease forwards;
  padding-top: 3rem;
}

.login__buttons_container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  padding: 0 0.75rem;
}

.login__cta_container {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.675rem;
  width: 100%;
  max-width: 100%;
  padding: 0 0.675rem;
}

.login__button_main {
  width: 100%;
  padding: 0.9rem;
  border: none;
  border-radius: 12px;
  font-size: 0.99rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  background: linear-gradient(45deg, #ffaa00, #e53540);
  color: white;
  position: relative;
  overflow: hidden;
}

.login__button_main:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(255, 170, 0, 0.2);
}

.login__button_try {
  width: 100%;
  padding: 1.2rem;
  border: 2px solid rgba(255, 170, 0, 0.3);
  border-radius: 12px;
  font-size: 1.1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  background: transparent;
  color: #4a3a1f;
  margin-top: 1rem;
}

.login__button_try:hover {
  background: rgba(255, 170, 0, 0.05);
  border-color: #ffaa00;
  transform: translateY(-2px);
}

.login__card_initial {
  padding: 2rem;
}

.login__card_try {
  padding: 2rem;
}

.login__card_login,
.login__card_register {
  padding: 2rem 1.5rem 1.5rem;
}

@keyframes slideIn {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.login__roles_grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 0.75rem;
  margin: 0.75rem 0;
  padding: 0.25rem;
  perspective: 1000px;
}

.login__role_card {
  position: relative;
  background: rgba(255, 255, 255, 0.95);
  min-height: 162px;
  border: none;
  border-radius: 24px;
  padding: 0.7875rem;
  text-align: center;
  cursor: pointer;
  transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.675rem;
  box-shadow: 0 8px 24px -4px rgba(255, 170, 0, 0.1);
  transform-style: preserve-3d;
  transform: translateZ(0);
  animation: fadeInScale 0.4s ease;
  transform-origin: center;
}

.login__role_card::before {
  content: '';
  position: absolute;
  inset: -2px;
  border-radius: 24px;
  padding: 2px;
  background: linear-gradient(
    135deg, 
    rgba(255, 170, 0, 0.5), 
    rgba(229, 53, 64, 0.5)
  );
  -webkit-mask: 
    linear-gradient(#fff 0 0) content-box, 
    linear-gradient(#fff 0 0);
  mask: 
    linear-gradient(#fff 0 0) content-box, 
    linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  opacity: 0;
  transition: opacity 0.4s ease;
}

.login__role_icon_wrapper {
  position: relative;
  width: 54px;
  height: 54px;
  border-radius: 14px;
  background: linear-gradient(135deg, rgba(255, 170, 0, 0.1), rgba(229, 53, 64, 0.1));
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
}

.login__role_icon {
  font-size: 2rem;
  color: #ffaa00;
  transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
  filter: drop-shadow(0 2px 4px rgba(255, 170, 0, 0.2));
}

.login__role_card h3 {
  font-size: 1.35rem;
  font-size: 1.5rem;
  font-weight: 700;
  color: #4a3a1f;
  margin: 0;
  transition: all 0.3s ease;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.login__role_card p {
  font-size: 0.9rem;
  font-size: 1rem;
  color: #5a4a2f;
  opacity: 0.8;
  margin: 0;
  line-height: 1.6;
}

.login__role_card:hover {
  transform: translateY(-8px) translateZ(20px);
  box-shadow: 0 20px 40px -12px rgba(255, 170, 0, 0.2);
  background: rgba(255, 255, 255, 0.98);
}

.login__role_card:hover .login__role_icon_wrapper {
  transform: scale(1.1) translateZ(10px);
  background: linear-gradient(135deg, rgba(255, 170, 0, 0.15), rgba(229, 53, 64, 0.15));
}

.login__role_card:hover .login__role_icon {
  transform: scale(1.1);
  color: #e53540;
}

.login__role_card_selected {
  transform: translateY(-8px) translateZ(30px);
  background: rgba(255, 255, 255, 1);
  box-shadow: 0 24px 48px -12px rgba(255, 170, 0, 0.25);
  animation: selectedRole 0.4s ease forwards;
}

.login__role_card_selected::before {
  opacity: 1;
}

.login__role_card_selected .login__role_icon_wrapper {
  transform: scale(1.1) translateZ(10px);
  background: linear-gradient(135deg, rgba(255, 170, 0, 0.2), rgba(229, 53, 64, 0.2));
}

.login__role_card_selected .login__role_icon {
  color: #e53540;
  transform: scale(1.1);
}

.login__role_card_selected h3 {
  background: linear-gradient(135deg, #ffaa00, #e53540);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

@media (max-width: 768px) {
  .login__roles_grid {
    grid-template-columns: 1fr;
    gap: 1.5rem;
    padding: 0.5rem;
  }
  
  .login__role_card {
    padding: 2rem;
    min-height: 200px;
  }

  .login__role_card:hover,
  .login__role_card_selected {
    transform: translateY(-4px) translateZ(10px);
  }
}

.login__spotify_button {
  width: 100%;
  padding: 1.2rem;
  margin: 2rem 0;
  border: none;
  border-radius: 12px;
  font-size: 1.1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  background: #1DB954;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}

.login__spotify_button:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(29, 185, 84, 0.2);
}

.login__skip_button {
  width: 100%;
  padding: 1rem;
  border: 2px solid rgba(90, 74, 47, 0.2);
  border-radius: 12px;
  background: transparent;
  color: #5a4a2f;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.3s ease;
}

.login__skip_button:hover {
  background: rgba(90, 74, 47, 0.05);
  border-color: #5a4a2f;
}

@media (max-width: 768px) {
  .login__back_button {
    top: 1rem;
    left: 1rem;
    padding: 0.5rem 0.875rem;
    font-size: 0.85rem;
  }
  
  .login__view {
    padding-top: 4rem;
  }
}

.login__view_initial {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1.5rem;
  width: 100%;
}

.login__hero {
  text-align: center;
  animation: fadeIn 0.6s ease-out;
  margin-bottom: 0.5rem;
  padding: 0;
}

.login__welcome {
  font-size: 1.5rem;
  line-height: 1.1;
  margin-bottom: 0.75rem;
  background: linear-gradient(135deg, #4a3a1f, #000);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.login__highlight {
  display: inline;
  background: linear-gradient(135deg, #ffaa00, #e53540);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 800;
}

.login__tagline {
  font-size: 1.1rem;
  color: #5a4a2f;
  opacity: 0.8;
  max-width: 100%;
  margin: 0.75rem auto 0;
  line-height: 1.6;
}

.login__overview_grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  margin: 3rem 0;
}

.login__overview_feature {
  background: white;
  padding: 2rem;
  border-radius: 16px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05);
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.login__overview_feature:hover {
  transform: translateY(-4px);
  box-shadow: 0 8px 30px rgba(255, 170, 0, 0.15);
}

.login__overview_icon {
  color: #ffaa00;
  margin-bottom: 1rem;
  filter: drop-shadow(0 2px 4px rgba(255, 170, 0, 0.2));
}

.login__overview_feature h3 {
  font-size: 1.4rem;
  color: #333;
  margin-bottom: 0.8rem;
}

.login__overview_feature p {
  color: #666;
  line-height: 1.5;
  font-size: 0.95rem;
}

.login__cta_container {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.675rem;
  width: 100%;
  max-width: 100%;
  padding: 0 1.5rem;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@media (max-width: 768px) {
  .login__welcome {
    font-size: 2.52rem;
  }
  
  .login__highlight {
    font-size: 2.4rem;
  }
  
  .login__overview_grid {
    grid-template-columns: 1fr;
  }
  
  .login__tagline {
    font-size: 1rem;
  }
  
  .login__overview_feature {
    padding: 1.5rem;
  }
}

.login__button_explore {
  background: linear-gradient(135deg, #ffaa00, #e53540);
  border: none;
  padding: 1rem;
  position: relative;
  overflow: hidden;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.login__button_explore::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.1), transparent);
  opacity: 0;
  transition: opacity 0.3s ease;
}

.login__button_explore:hover::before {
  opacity: 1;
}

.login__button_auth {
  background: transparent;
  border: 2px solid rgba(255, 170, 0, 0.3);
  padding: 1rem;
  color: #4a3a1f;
}

.login__button_subtext {
  font-size: 0.765rem;
  font-weight: normal;
  opacity: 0.8;
  display: flex;
  align-items: center;
  gap: 0.3rem;
  color: currentColor;
}

.login__button_explore .login__button_subtext {
  color: rgba(255, 255, 255, 0.9);
}

.login__button_auth:hover {
  background: rgba(255, 170, 0, 0.05);
  border-color: #ffaa00;
  transform: translateY(-2px);
}

.login__button_explore:hover {
  transform: translateY(-2px);
  box-shadow: 0 8px 20px rgba(255, 170, 0, 0.2);
}

.login__button_content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.4rem;
  width: 100%;
}

.login__button_main_text {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 1.2rem;
  font-weight: 600;
}

.login__button_icon {
  width: 20px;
  height: 20px;
}

@media (max-width: 768px) {
  .login__button_main_text {
    font-size: 0.99rem;
  }
  
  .login__button_subtext {
    font-size: 0.8rem;
  }
  
  .login__welcome {
    font-size: 2.52rem;
  }
  
  .login__tagline {
    font-size: 1rem;
  }
}

.login__button_explore {
  background: linear-gradient(45deg, #ffaa00, #e53540);
  border: none;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
}

.login__button_auth {
  background: transparent;
  border: 2px solid rgba(255, 170, 0, 0.3);
  color: #4a3a1f;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
}

.login__button_auth:hover {
  background: rgba(255, 170, 0, 0.05);
  border-color: #ffaa00;
}

.login__button_subtext {
  font-size: 0.9rem;
  font-weight: normal;
  opacity: 0.8;
}

.login__role_badge {
  background: linear-gradient(135deg, #ffaa00, #e53540);
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 20px;
  font-size: 0.9rem;
  font-weight: 600;
  margin-bottom: 1.5rem;
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  animation: slideInDown 0.4s ease;
}

.login__selected_role {
  text-align: center;
  margin-bottom: 1rem;
}

.login__text_button {
  background: none;
  border: none;
  color: #e53540;
  font-weight: 600;
  cursor: pointer;
  padding: 0;
  margin: 0;
  text-decoration: underline;
}

@keyframes fadeInScale {
  from {
    opacity: 0;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes selectedRole {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes slideInDown {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.login__button_register {
  background: linear-gradient(135deg, #e53540, #ffaa00);
  color: white;
}

.login__auth_options {
  width: 100%;
  margin-top: 2rem;
  animation: fadeIn 0.6s ease-out 0.3s both;
}

.login__auth_separator {
  display: flex;
  align-items: center;
  text-align: center;
  margin: 1.5rem 0;
  color: #5a4a2f;
  opacity: 0.5;
}

.login__auth_separator::before,
.login__auth_separator::after {
  content: '';
  flex: 1;
  border-bottom: 2px solid rgba(90, 74, 47, 0.2);
}

.login__auth_separator span {
  margin: 0 10px;
  font-size: 0.9rem;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.login__auth_buttons {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 1rem;
}

.login__auth_button {
  width: 100%;
  padding: 1rem;
  border: 2px solid rgba(90, 74, 47, 0.2);
  border-radius: 12px;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.75rem;
  background: transparent;
  color: #4a3a1f;
}

.login__auth_button_signin:hover {
  border-color: #ffaa00;
  background: rgba(255, 170, 0, 0.05);
  transform: translateY(-2px);
}

.login__auth_button_register {
  background: linear-gradient(135deg, #ffaa00, #e53540);
  border: none;
  color: white;
}

.login__auth_button_register:hover {
  transform: translateY(-2px);
  box-shadow: 0 8px 20px rgba(255, 170, 0, 0.2);
}

.login__view_auth_choice {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  text-align: center;
}

.login__auth_options {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  max-width: 400px;
  margin: 2rem auto 0;
  animation: fadeIn 0.6s ease-out;
}

.login__auth_button {
  width: 100%;
  padding: 1.5rem;
  border: 2px solid rgba(90, 74, 47, 0.2);
  border-radius: 16px;
  font-size: 1.1rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.75rem;
  background: white;
  color: #4a3a1f;
}

.login__auth_button_signin:hover {
  border-color: #ffaa00;
  background: rgba(255, 170, 0, 0.05);
  transform: translateY(-2px);
}

.login__auth_button_register {
  background: linear-gradient(135deg, #ffaa00, #e53540);
  border: none;
  color: white;
}

.login__auth_button_register:hover {
  transform: translateY(-2px);
  box-shadow: 0 8px 20px rgba(255, 170, 0, 0.2);
}

.login__selected_roles {
  margin: 0.5rem 0;
  padding: 0.375rem;
  border-radius: 12px;
  background: rgba(255, 255, 255, 0.5);
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  text-align: center;
  animation: fadeIn 0.3s ease;
}

.login__role_tags {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  justify-content: center;
  margin: 1rem 0;
}

.login__role_tag {
  background: linear-gradient(135deg, rgba(255, 170, 0, 0.05), rgba(229, 53, 64, 0.05));
  border: 2px solid rgba(255, 170, 0, 0.2);
  padding: 0.3rem 0.75rem;
  border-radius: 20px;
  font-size: 0.85rem;
  font-weight: 600;
  color: #4a3a1f;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  animation: fadeInScale 0.3s ease;
}

.login__action_container {
  margin-top: 1rem;
  padding: 0.75rem;
  text-align: center;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0), white 20%);
  width: 100%;
}

.login__button_primary {
  margin: 0 auto;
  width: 100%;
  max-width: 300px;
  padding: 1rem 1.5rem;
  border: none;
  border-radius: 12px;
  background: linear-gradient(135deg, #ffaa00, #e53540);
  color: white;
  font-size: 1.1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
}

.login__roles_grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 0.75rem;
  margin: 0.75rem 0;
  padding: 0.25rem;
}

.login__role_card {
  position: relative;
  background: rgba(255, 255, 255, 0.95);
  min-height: 162px;
  border: none;
  border-radius: 24px;
  padding: 0.7875rem;
  text-align: center;
  cursor: pointer;
  transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.675rem;
  box-shadow: 0 8px 24px -4px rgba(255, 170, 0, 0.1);
}

.login__roles_hint {
  color: #5a4a2f;
  opacity: 0.7;
  font-size: 0.9rem;
  text-align: center;
  padding: 1rem;
  background: white;
  border-radius: 12px;
  border: 2px solid rgba(90, 74, 47, 0.1);
}

.login__role_tags {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  justify-content: center;
  margin: 1rem 0;
}

.login__role_tag {
  background: linear-gradient(135deg, rgba(255, 170, 0, 0.1), rgba(229, 53, 64, 0.1));
  border: 2px solid rgba(255, 170, 0, 0.3);
  padding: 0.5rem 1rem;
  border-radius: 20px;
  font-size: 0.9rem;
  font-weight: 600;
  color: #4a3a1f;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  animation: fadeInScale 0.3s ease;
}

.login__role_tag_remove {
  background: none;
  border: none;
  padding: 2px;
  margin-left: 4px;
  color: #4a3a1f;
  opacity: 0.5;
  cursor: pointer;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login__role_tag_remove:hover {
  opacity: 1;
  color: #e53540;
}

.login__roles_hint {
  color: #5a4a2f;
  opacity: 0.7;
  font-size: 0.9rem;
  text-align: center;
  padding: 1rem;
}

@keyframes slideUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.login__button_continue {
  background: linear-gradient(135deg, #ffaa00, #e53540);
  width: 100%;
  max-width: none;
  margin: 0;
  font-size: 1rem;
  opacity: 1;
}

.login__button_continue.login__button_disabled {
  background: rgba(90, 74, 47, 0.1);
  color: #5a4a2f;
  cursor: default;
  transform: none;
  box-shadow: none;
  opacity: 1;
}

.login__password_toggle {
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  padding: 8px;
  cursor: pointer;
  color: #5a4a2f;
  opacity: 0.6;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
}

.login__password_toggle:hover {
  opacity: 1;
  color: #ffaa00;
}

.login__password_toggle:focus {
  outline: none;
  opacity: 1;
}

.login__password_toggle svg {
  width: 20px;
  height: 20px;
  stroke-width: 2;
}

.login__selected_roles {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  justify-content: center;
  margin: 0.5rem 0;
  padding: 0.375rem;
}

.login__role_tag {
  background: linear-gradient(135deg, rgba(255, 170, 0, 0.1), rgba(229, 53, 64, 0.1));
  border: 2px solid rgba(255, 170, 0, 0.3);
  padding: 0.5rem 1rem;
  border-radius: 20px;
  font-size: 0.9rem;
  font-weight: 600;
  color: #4a3a1f;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  animation: fadeInScale 0.3s ease;
}

@keyframes fadeInScale {
  from {
    opacity: 0;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.login__view_welcome {
  animation: fadeIn 0.5s ease-out;
  text-align: center;
  padding: 2rem;
  max-width: 480px;
  margin: 0 auto;
}

.login__view_welcome .login__hero {
  margin-bottom: 2.5rem;
}

.login__view_welcome .login__welcome {
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 1rem;
  background: linear-gradient(135deg, #2c5282, #4299e1);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  line-height: 1.2;
}

.login__view_welcome .login__highlight {
  background: linear-gradient(135deg, #6b46c1, #805ad5);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 800;
}

.login__view_welcome .login__tagline {
  color: #4a5568;
  font-size: 1.1rem;
  margin-top: 1rem;
  opacity: 0.9;
}

.login__view_welcome .login__cta_container {
  margin-top: 2rem;
}

.login__view_welcome .login__button_main {
  background: linear-gradient(135deg, #4c51bf, #6b46c1);
  color: white;
  padding: 1rem 2rem;
  border-radius: 12px;
  font-weight: 600;
  font-size: 1.1rem;
  border: none;
  cursor: pointer;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  width: 100%;
  max-width: 320px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 6px rgba(107, 70, 193, 0.2);
}

.login__view_welcome .login__button_main:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 12px rgba(107, 70, 193, 0.3);
}

.login__view_welcome .login__button_main:active {
  transform: translateY(0);
}

.login__view_welcome .login__button_content {
  display: flex;
  align-items: center;
  gap: 0.75rem;
}

.login__view_welcome .login__button_icon {
  width: 1.25rem;
  height: 1.25rem;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@media (max-width: 768px) {
  .login__view_welcome .login__welcome {
    font-size: 2rem;
  }

  .login__view_welcome .login__tagline {
    font-size: 1rem;
  }

  .login__view_welcome .login__button_main {
    padding: 0.875rem 1.75rem;
    font-size: 1rem;
  }
}

.login__loading {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
}

.login__loading p {
  color: #4a5568;
  font-size: 1.1rem;
  opacity: 0.8;
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    opacity: 0.6;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.6;
  }
}

.login__view_welcome {
  opacity: 0;
  animation: fadeIn 0.5s ease-out forwards;
}

.login__view_compact {
  padding: 1rem 0.75rem;
}

.login__trial_container_compact {
  padding: 0.125rem;
  margin: 0.375rem 0;
}

.login__view_try {
  height: 80%;
  display: flex;
  flex-direction: column;
}

.login__trial_container {
  flex: 1;
  overflow-y: auto;
  padding: 0.25rem;
}

@media (max-width: 1000px) {
  .login__button_main {
    font-size: 1rem;
    padding: 0.8rem;
  }

  .login__title {
    font-size: 1.8rem;
  }

  .login__subtitle {
    font-size: 0.9rem;
  }

  .login__trial_artist {
    font-size: 0.9rem;
    padding: 0.7rem 1.2rem;
  }
}

@media (max-width: 800px) {
  .login__container {
    width: 95%;
    margin: 0.5rem;
  }

  .login__logo {
    width: 140px;
  }

  .login__card {
    min-height: 280px;
    padding: 0.4rem;
  }

  .login__button_main_text {
    font-size: 0.9rem;
  }

  .login__trial_container {
    gap: 0.3rem;
  }

  .login__role_card {
    min-height: 140px;
    padding: 0.6rem;
  }

  .login__role_icon_wrapper {
    width: 45px;
    height: 45px;
  }
}

@media (max-width: 600px) {
  .login__page {
    padding-top: 20px;
  }

  .login__container {
    width: 92%;
    margin: 0.3rem;
  }

  .login__card {
    margin-top: 10px;
    min-height: 260px;
  }

  .login__logo {
    width: 120px;
    margin-bottom: 0.3rem;
  }

  .login__title {
    font-size: 1.6rem;
  }

  .login__subtitle {
    font-size: 0.85rem;
    margin-bottom: 0.5rem;
  }

  .login__trial_artist {
    font-size: 0.85rem;
    padding: 0.6rem 1rem;
    margin: 0.2rem;
  }

  .login__button_main {
    font-size: 0.9rem;
    padding: 0.7rem;
  }

  .login__button_icon {
    width: 18px;
    height: 18px;
  }

  .login__role_card {
    padding: 0.5rem;
    min-height: 120px;
    gap: 0.4rem;
  }

  .login__role_icon_wrapper {
    width: 40px;
    height: 40px;
  }

  .login__role_card h3 {
    font-size: 1.1rem;
  }

  .login__role_card p {
    font-size: 0.8rem;
  }

  .login__view {
    padding: 3.5rem 0.5rem 0.5rem;
  }

  .login__back_button {
    top: 0.5rem;
    left: 0.5rem;
    padding: 0.4rem 0.8rem;
    font-size: 0.8rem;
    z-index: 100;
  }

  .login__title {
    margin-top: 1rem;
  }

  .login__card {
    padding-top: 1rem;
  }

  .login__back_button {
    padding: 0.4rem 0.8rem;
    font-size: 0.8rem;
  }

  .login__error {
    font-size: 0.8rem;
  }

  .login__welcome {
    font-size: 1.8rem;
  }

  .login__tagline {
    font-size: 0.9rem;
  }

  .login__role_tag {
    font-size: 0.8rem;
    padding: 0.3rem 0.6rem;
  }
}

@media (max-width: 400px) {
  .login__container {
    width: 90%;
  }

  .login__logo {
    width: 100px;
  }

  .login__title {
    font-size: 1.4rem;
  }

  .login__subtitle {
    font-size: 0.8rem;
  }

  .login__button_main {
    font-size: 0.85rem;
    padding: 0.6rem;
  }

  .login__trial_artist {
    font-size: 0.8rem;
    padding: 0.5rem 0.8rem;
  }

  .login__role_card {
    min-height: 110px;
  }

  .login__role_icon_wrapper {
    width: 35px;
    height: 35px;
  }

  .login__welcome {
    font-size: 1.6rem;
  }

  .login__view {
    padding: 3rem 0.5rem 0.5rem;
  }

  .login__back_button {
    top: 0.4rem;
    left: 0.4rem;
  }
}

.error_container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 2rem;
  background: rgba(255, 255, 255, 0.95);
}

.error_card {
  background: white;
  border-radius: 20px;
  padding: 2.5rem;
  text-align: center;
  max-width: 400px;
  width: 100%;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  animation: slideIn 0.4s ease;
}

.error_icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 64px;
  height: 64px;
  background: rgba(229, 53, 64, 0.1);
  border-radius: 50%;
  margin-bottom: 1.5rem;
}

.error_icon svg {
  color: #e53540;
}

.error_title {
  font-size: 1.5rem;
  color: #4a3a1f;
  margin-bottom: 1rem;
  font-weight: 700;
}

.error_message {
  color: #5a4a2f;
  margin-bottom: 0.5rem;
  font-size: 1.1rem;
  line-height: 1.5;
}

.error_detail {
  color: #e53540;
  font-size: 0.9rem;
  margin-bottom: 1.5rem;
  padding: 0.75rem;
  background: rgba(229, 53, 64, 0.1);
  border-radius: 8px;
  word-break: break-word;
}

.error_button {
  background: linear-gradient(135deg, #ffaa00, #e53540);
  color: white;
  border: none;
  padding: 1rem 2rem;
  border-radius: 12px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  width: 100%;
}

.error_button:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(229, 53, 64, 0.2);
}

.loading_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding: 2rem;
  background: rgba(255, 255, 255, 0.95);
  gap: 1.5rem;
}

.loading_spinner {
  width: 40px;
  height: 40px;
  border: 3px solid rgba(255, 170, 0, 0.2);
  border-top-color: #ffaa00;
  border-radius: 50%;
  animation: spin 1s ease infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.login__button_beta {
  background: linear-gradient(135deg, #6b46c1, #4299e1);
  color: white;
  border: none;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.login__button_beta:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(107, 70, 193, 0.2);
}

.login__button_text_desktop {
  display: inline;
}

.login__button_text_mobile {
  display: none;
}

@media (max-width: 480px) {
  .login__button_text_desktop {
    display: none;
  }

  .login__button_text_mobile {
    display: inline;
  }
  
  .login__button_beta {
    padding: 0.8rem;
    font-size: 0.9rem;
  }
}

