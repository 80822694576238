.audienceFinderFullscreen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9999;
  background: linear-gradient(135deg, #f3f1e6 0%, #e8e5d9 100%);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  font-family: 'Poppins', sans-serif;
  color: #4a3a1f;
}

li {
  margin-top: 10px;
  list-style-type: none;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  backdrop-filter: blur(10px);
}

.headerLeft,
.headerRight {
  display: flex;
  gap: 15px;
}

.headerCenter {
  text-align: center;
}

.title {
  font-size: 24px;
  font-weight: 600;
  margin: 0;
  color: #ff9900;
}

.description {
  font-size: 14px;
  color: #666;
  margin: 5px 0;
}

.breadcrumbs {
  display: flex;
  justify-content: center;
  gap: 10px;
  font-size: 14px;
  color: #4a3a1f;
}

.breadcrumbSeparator {
  color: #ff9900;
}

.breadcrumbButton {
  background: none;
  border: none;
  color: #4a3a1f;
  cursor: pointer;
  transition: color 0.3s;
}

.breadcrumbButton:hover,
.breadcrumbButton.current {
  color: #ff9900;
}



.takeNote {
  display: inline-flex;
  align-items: center;
  background-color: #4a3a1f;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 20px;
  justify-content: center;
  align-self: center;
  transition: background-color 0.3s;
}

.takeNote:hover {
  background-color: #362b15;
}

.takeNote::before {
  margin-right: 5px;
}

.takeNote:active {
  background-color: red;
}


.homeButton,
.dashboardButton,
.controlButton,
.helpButton{
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(45deg, #ffaa00, #ff9900);
  color: #ffffff;
  border: none;
  padding: 10px 15px;
  border-radius: 25px;
  cursor: pointer;
  font-size: 14px;
  transition: transform 0.3s, box-shadow 0.3s;
  text-align: center;
}


.dashboardButton:hover,
.controlButton:hover,
.helpButton:hover,
.exportButton:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  background: linear-gradient(45deg, #ff9900, #ffaa00);
}


.exportButton{
  position: absolute;
  bottom: 20px;
  left: 20px;
  display: inline-flex;
  align-items: center;
  background-color: #4a3a1f;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
}

.exportButton:hover {
  background-color: #362b15;
}

.backButton {
  position: fixed;
  bottom: 30px;
  right: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(45deg, #ffaa00, #ff9900);
  color: #ffffff;
  border: none;
  padding: 12px 24px;
  border-radius: 25px;
  cursor: pointer;
  font-size: 14px;
  transition: transform 0.3s, box-shadow 0.3s;
  text-align: center;
  z-index: 1000;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.backButton:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  background: linear-gradient(45deg, #ff9900, #ffaa00);
}

.backButton:disabled {
  opacity: 0.5;
  cursor: not-allowed;
  transform: none;
}

.backButton .icon {
  margin-right: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}


.icon {
  margin-right: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mainContent {
  flex-grow: 1;
  position: relative;
  padding: 20px;
}

.graphControls {
  position: absolute;
  top: 20px;
  right: 20px;
  display: flex;
  gap: 10px;
  z-index: 10;
}

.nodeDetails {
  position: absolute;
  bottom: 20px;
  left: 20px;
  background: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(10px);
  padding: 20px;
  border-radius: 10px;
  width: 300px;
  transition: width 0.3s;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  opacity: 0;
  pointer-events: none;
  transform: translateY(20px);
}

.nodeDetails.expanded {
  width: 400px;
  opacity: 1;
  pointer-events: auto;
  transform: translateY(0);
}

.nodeContent h3 {
  font-size: 18px;
  font-weight: 600;
  margin: 0 0 5px;
  color: #ff9900;
}

.nodeType {
  font-size: 12px;
  color: #666;
  margin-bottom: 10px;
}
.helpOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  background: linear-gradient(135deg, #f3f1e6 0%, #e8e5d9 100%);
  backdrop-filter: blur(5px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000;
}

.helpContent {
  background: transparent;
  padding: 40px;
  width: 90%;
  max-width: 1200px;
  z-index: 10001;
  position: relative;
}

.helpContent h2 {
  font-size: 28px;
  font-weight: 600;
  margin: 0 0 30px;
  color: #ff9900;
  text-align: center;
}

.helpGrid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 25px;
  margin-top: 30px;
}

.helpItem {
  background: white;
  padding: 30px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
}

.iconContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.helpIcon {
  width: 100px;
  height: 50px;
  min-width: 100px;
  min-height: 50px;
  border-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  font-size: 24px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  flex-shrink: 0;
  flex-grow: 0;
}

.helpItem div {
  text-align: center;
  width: 100%;
}

.helpLabel h3 {
  font-size: 20px;
  font-weight: 600;
  margin: 0 0 10px;
  color: #4a3a1f;
  text-align: center;
}

.helpItem p {
  margin: 0;
  font-size: 16px;
  line-height: 1.5;
  color: #666;
  text-align: center;
}

.closeHelp {
  background: #ff9900;
  color: #fff;
  border: none;
  padding: 12px 40px;
  border-radius: 25px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  transition: all 0.3s;
  margin: 40px auto 0;
  display: block;
  width: fit-content;
}

.closeHelp:hover {
  transform: translateY(-2px);
  background: #ffaa00;
}


.loadingOverlay,
.errorOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #f3f1e6;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 9999;
}

.loadingSpinner {
  width: 50px;
  height: 50px;
  border: 3px solid #ff9900;
  border-top-color: #ffaa00;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  margin-bottom: 20px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.errorContent {
  text-align: center;
}

.errorContent h2 {
  font-size: 24px;
  font-weight: 600;
  margin: 0 0 10px;
  color: #ff9900;
}

.errorContent button {
  background: #ff9900;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 25px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s;
  margin-top: 20px;
}

.nodeInfoBox {
  position: fixed;
  top: 50%;
  left: 20px;
  transform: translateY(-50%);
  width: 400px;
  max-height: 80vh;
  overflow-y: auto;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
  padding: 20px;
  font-family: 'Poppins', sans-serif;
  color: #4a3a1f;
}

.nodeInfoHeader {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.nodeInfoHeader h2 {
  font-size: 2rem;
  font-weight: 600;
  margin: 0;
  color: #ff9900;
  text-align: center;
}

.nodeInfoContent {
  margin-bottom: 20px;
}

.nodeInfoContent h4 {
  font-size: 1.8rem;
  font-weight: 600;
  margin-bottom: 15px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: #4a3a1f;
}

.nodeInfoContent h4::before {
  margin-right: 8px;
}

.infoItem {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.infoLabel {
    color: #4a3a1f;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 2%;
}

.infoValue {
  color: #666666;
}

.infoList {
  margin: 0;
  padding-left: 20px;

}

.infoList li {
  margin-bottom: 5px;
}

.section {
  background-color: #f5f5f5;
  border-radius: 5px;
  padding: 15px;
  margin-bottom: 20px;
}

.section strong {
  font-weight: 600;
}

.section ul {
  margin: 0;
  padding-left: 20px;
}

.tagContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 8px;
  justify-content: center;
  align-items: center;
  margin: 10px 0;
}

.tag {
  background-color: #ff9900;
  color: white;
  border-radius: 20px;
  padding: 8px 16px;
  font-size: 1rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
}

.audienceInfo {
  background-color: rgba(255, 153, 0, 0.1);
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 10px;
}

.audienceInfo p {
  margin: 5px 0;
}

.nodeInfoActions {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.actionButton {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #ff9900;
  color: #ffffff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  margin: 0 10px;
  transition: background-color 0.3s;
}

.actionButton:hover {
  background-color: #e68a00;
}

.actionButton:active {
  background-color: #cc7a00;
}

.icon {
  margin-right: 5px;
}


.artist {
  border-top: 4px solid #FF4B4B;
}

.current_audience {
  border-top: 4px solid #4ECDC4;
}

.potential_audience {
  border-top: 4px solid #45B7D1;
}

.content_action {
  border-top: 4px solid #FFA07A;
}

.brand_perception {
  border-top: 4px solid #F7DC6F;
}

/* Custom scrollbar */
.nodeInfoBox::-webkit-scrollbar {
  width: 8px;
}

.nodeInfoBox::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.nodeInfo ::-webkit-scrollbar-thumb {
  background-color: #ff9900;
  border-radius: 4px;
}


.errorContent button:hover {
  background: #ffaa00;
}

:global(.force-graph-container) {
  background: transparent !important;
  width: 100% !important;
  height: 100% !important;
}

:global(.force-graph-container canvas) {
  width: 100% !important;
  height: 100% !important;
}


.notFoundOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(135deg, #f3f1e6 0%, #e8e5d9 100%);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.notFoundContent {
  background: white;
  padding: 40px;
  border-radius: 20px;
  text-align: center;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  animation: slideUp 0.5s ease-out forwards;
  max-width: 400px;
  width: 90%;
}

.notFoundIcon {
  font-size: 64px;
  margin-bottom: 20px;
  animation: pulse 2s infinite;
}

.notFoundContent h2 {
  color: #ff9900;
  font-size: 28px;
  margin: 0 0 10px;
  font-weight: 600;
}

.notFoundContent p {
  color: #666;
  font-size: 16px;
  margin: 0 0 20px;
}

.redirectMessage {
  color: #999;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.loadingDots {
  display: flex;
  gap: 2px;
}

.loadingDots span {
  animation: dots 1.4s infinite;
  opacity: 0;
}

.loadingDots span:nth-child(2) {
  animation-delay: 0.2s;
}

.loadingDots span:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes slideUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes dots {
  0%, 100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

.mobileNodeInfoOverlay {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  max-height: 85vh;
  z-index: 1000;
  touch-action: none;
  background: white;
  border-radius: 20px 20px 0 0;
  box-shadow: 0 -4px 20px rgba(0, 0, 0, 0.15);
}

.mobileNodeInfo {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.dragIndicator {
  width: 40px;
  height: 4px;
  background: #ddd;
  border-radius: 2px;
  margin: 12px auto;
}

.closeButton {
  position: absolute;
  top: 12px;
  right: 12px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.1);
  color: #666;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 16px;
  z-index: 1;
}


.mobileNodeInfoOverlay {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  touch-action: none;
}

.mobileNodeInfo {
  background: rgba(255, 255, 255, 0.95);
  backdrop-filter: blur(10px);
  border-radius: 20px 20px 0 0;
  overflow: hidden;
  box-shadow: 0 -4px 20px rgba(0, 0, 0, 0.1);
  position: relative; /* For close button positioning */
}

.dragIndicator {
  width: 40px;
  height: 4px;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 2px;
  margin: 8px auto;
}

.closeButton {
  position: absolute;
  top: 12px;
  right: 12px;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: #666;
  font-size: 18px;
  z-index: 2;
}

.mobileNodeInfoContent {
  padding: 20px;
  height: calc(90vh - 60px); 
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}


.mobileNodeInfoContent::-webkit-scrollbar {
  display: none;
}

/* Note Panels */
.notesPanel {
  position: fixed;
  top: 0;
  right: 0;
  width: 300px;
  height: 100vh;
  background: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(10px);
  transform: translateX(100%);
  transition: transform 0.3s ease-out;
  z-index: 1000;
}

.notesPanel.open {
  transform: translateX(0);
}

.notesList {
  height: 100%;
  overflow-y: auto;
}

.notesHeader {
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  color: #4a3a1f;
}

.headerActions {
  display: flex;
  gap: 10px;
}

.actionButton {
  background: none;
  border: none;
  padding: 8px;
  cursor: pointer;
  color: #4a3a1f;
}

.noteItem {
  padding: 15px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.noteName {
  font-size: 14px;
  color: #4a3a1f;
}

.noteTime {
  font-size: 12px;
  color: #666;
}

/* Base Mobile Viewport Optimization */
@media screen and (max-width: 768px) {
  .hoverInfo {
    display: none;
  }


  .backButton {
    bottom: calc(15vh + 20px);
    transition: bottom 0.3s ease-out;
  }

  .exportButton {
    bottom: calc(15vh + 20px);
    transition: bottom 0.3s ease-out;
  }

  /* When drawer is expanded */
  .drawerExpanded .backButton,
  .drawerExpanded .exportButton {
    bottom: calc(75vh + 20px);
  }

  .audienceFinderFullscreen {
    font-size: 14px;
    overflow-x: hidden;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
  }




  .header {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 100;
    padding: 8px 8px 2px 8px;
    background: transparent;
    pointer-events: none;
  }


  .headerLeft,
  .headerRight,
  .headerCenter,
  button {
    pointer-events: auto;
  }

  .headerLeft {
    position: absolute;
    top: 8px;
    left: 8px;
    display: flex;
    gap: 8px;
  }

  .headerRight {
    position: absolute;
    top: 8px;
    right: 8px;
    display: flex;
    gap: 8px;
  }

  .headerCenter {
    width: 100%;
    padding: 35px 5px 0 5px;
  }

  .title {
    font-size: 16px;
    line-height: 1.2;
    margin-bottom: 2px;
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  }

  .description {
    font-size: 11px;
    margin-bottom: 4px;
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  }


  .homeButton,
  .dashboardButton,
  .helpButton {
    width: 36px;
    height: 36px;
    padding: 0;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(255, 153, 0, 0.9);
    backdrop-filter: blur(4px);
  }

  .homeButton span,
  .dashboardButton span,
  .helpButton span {
    display: none;
  }


  .backButton {
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 40px;
    height: 40px;
    padding: 0;
    border-radius: 50%;
    z-index: 1100;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(255, 153, 0, 0.9);
    backdrop-filter: blur(4px);
  }

  .backButton span {
    display: none;
  }


  .exportButton {
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    padding: 10px 24px;
    border-radius: 24px;
    z-index: 1100;
    display: flex;
    align-items: center;
    gap: 8px;
    background: #4a3a1f;
    color: white;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    font-size: 14px;
  }

  .exportButton:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  .exportButton .icon {
    margin-right: 4px;
    width: 16px;
    height: 16px;
  }



  .breadcrumbs {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 6px;
    font-size: 12px;
    padding: 0 10px;
  }

  .breadcrumbButton {
    font-size: 12px;
    padding: 2px 6px;
    white-space: nowrap;
  }
}

/* Landscape Adjustments */
@media screen and (max-width: 768px) and (orientation: landscape) {


  .headerCenter {
    padding-top: 30px;
  }


  .homeButton,
  .dashboardButton,
  .helpButton {
    width: 32px;
    height: 32px;
  }

  .backButton {
    width: 36px;
    height: 36px;
    bottom: 16px;
    right: 16px;
  }

  .exportButton {
    padding: 8px 20px;
    font-size: 12px;
    bottom: 16px;
  }

  .icon {
    width: 16px;
    height: 16px;
  }
}