.intro-overlay,
.intro-fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
}

.intro-overlay {
  background: rgba(242,241,231,255);
  z-index: 9998;
}

.intro-fullscreen {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 9999; 
  pointer-events: all !important;
}

.phase-initial .intro-overlay,
.phase-network .intro-overlay,
.phase-bloom1 .intro-overlay,
.phase-bloom2 .intro-overlay,
.phase-bloom3 .intro-overlay,
.phase-final .intro-overlay {
  opacity: 0;
  background: transparent;
}

.intro-fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 99999;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  overflow: hidden;
}

.network-container {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: radial-gradient(
    circle at 50% 50%,
    rgba(255, 170, 0, 0.05) 0%,
    rgba(229, 53, 64, 0.02) 50%,
    transparent 100%
  );
  filter: drop-shadow(0 0 100px rgba(255, 170, 0, 0.1));
}

.network-node {
  position: absolute;
  transform: translate(-50%, -50%) scale(0);
  opacity: 0;
  transition: transform 1.5s cubic-bezier(0.4, 0, 0.2, 1),
              opacity 1.5s cubic-bezier(0.4, 0, 0.2, 1);
  transition-delay: var(--delay);
  will-change: transform, opacity;
}

.network-node.visible {
  transform: translate(-50%, -50%) scale(1);
  opacity: 1;
}


.node-core {
  width: var(--size);
  height: var(--size);
  border-radius: 50%;
  background: radial-gradient(
    circle at 30% 30%,
    rgba(255, 255, 255, 0.95) 0%,
    var(--color) 60%,
    rgba(0, 0, 0, 0.1) 100%
  );
  position: relative;
  z-index: 2;
  box-shadow: 
    0 0 30px var(--color),
    0 0 60px var(--glow-color),
    inset 0 0 20px rgba(255, 255, 255, 0.8);
  animation: nodeGlow 3s ease-in-out infinite;
}

.node-pulse {
  position: absolute;
  inset: -50%;
  border-radius: 50%;
  background: radial-gradient(
    circle,
    var(--color) 0%,
    transparent 70%
  );
  opacity: 0;
  animation: nodePulse 2s cubic-bezier(0.4, 0, 0.2, 1) infinite;
  animation-delay: calc(var(--delay) + 1s);
}

.node-halo {
  position: absolute;
  inset: -100%;
  border-radius: 50%;
  background: radial-gradient(circle,
    var(--color) 0%,
    transparent 70%
  );
  opacity: 0.2;
  filter: blur(8px);
  animation: nodeHalo 6s ease-in-out infinite;
}

.intro-text {
  position: relative;
  z-index: 2;
  text-align: center;
  opacity: 0;
  transform: translateY(20px);
  transition: all 1s cubic-bezier(0.4, 0, 0.2, 1);
  padding: clamp(1rem, 5vw, 3rem);
  border-radius: 20px;
  background: rgba(255, 255, 255, 0.95);
  border: none;
  box-shadow: 
    0 20px 60px -10px rgba(255, 170, 0, 0.3),
    0 0 20px rgba(255, 170, 0, 0.1),
    inset 0 0 0 1px rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(20px);
  max-width: min(90vw, 600px);
  margin: 0 auto;
  transform: perspective(1000px) rotateX(0deg);
  transition: all 0.8s cubic-bezier(0.4, 0, 0.2, 1);
}

.phase-network .intro-text,
.phase-bloom1 .intro-text,
.phase-bloom2 .intro-text,
.phase-bloom3 .intro-text,
.phase-final .intro-text {
  opacity: 1;
  transform: translateY(0);
}


.intro-text h1 {
  font-size: clamp(1.8rem, 5vw, 3.5rem);
  color: #4a3a1f;
  margin-bottom: clamp(1rem, 3vw, 2rem);
  font-weight: 700;
}

.feature-list {
  display: flex;
  flex-direction: column;
  gap: clamp(1.2rem, 2.5vw, 2rem);
  transform: translateZ(0);
  will-change: transform;
}

.feature {
  position: relative;
  font-size: clamp(1.2rem, 3vw, 1.8rem);
  opacity: 0;
  transform: translateX(-50px) scale(0.95);
  transition: all 0.6s cubic-bezier(0.34, 1.56, 0.64, 1);
  padding: 1.2rem 1.8rem;
  border-radius: 16px;
  background: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(8px);
  box-shadow: 
    0 10px 30px -5px rgba(0, 0, 0, 0.1),
    0 0 0 1px rgba(255, 255, 255, 0.3);
}

.feature::before {
  content: '';
  position: absolute;
  inset: 0;
  border-radius: 12px;
  background: linear-gradient(135deg, rgba(255, 170, 0, 0.1), rgba(229, 53, 64, 0.1));
  opacity: 0;
  transition: opacity 0.3s ease;
}

.phase-network .feature:nth-child(1),
.phase-bloom1 .feature:nth-child(2),
.phase-bloom2 .feature:nth-child(3),
.phase-bloom3 .feature:nth-child(4) {
  opacity: 1;
  transform: translateX(0) scale(1);
  background: rgba(255, 255, 255, 0.95);
  box-shadow: 
    0 20px 40px -10px rgba(255, 170, 0, 0.2),
    inset 0 0 0 1px rgba(255, 170, 0, 0.1),
    0 0 0 4px rgba(255, 255, 255, 0.4);
  animation: featureActivate 0.6s cubic-bezier(0.34, 1.56, 0.64, 1);
}

.phase-network .feature:nth-child(1) { transition-delay: 0.3s; }
.phase-bloom1 .feature:nth-child(2) { transition-delay: 0.3s; }
.phase-bloom2 .feature:nth-child(3) { transition-delay: 0.3s; }
.phase-bloom3 .feature:nth-child(4) { transition-delay: 0.3s; }

.feature span {
  background: linear-gradient(135deg, #ffaa00, #e53540);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  opacity: 0;
  transform: translateY(10px);
  transition: all 0.5s ease;
  transition-delay: 0.2s;
}

.phase-network .feature:nth-child(1) span,
.phase-bloom1 .feature:nth-child(2) span,
.phase-bloom2 .feature:nth-child(3) span,
.phase-bloom3 .feature:nth-child(4) span {
  opacity: 1;
  transform: translateY(0);
}

@keyframes shimmer {
  0% { background-position: -200% center; }
  100% { background-position: 200% center; }
}

.phase-network .feature:nth-child(1)::before,
.phase-bloom1 .feature:nth-child(2)::before,
.phase-bloom2 .feature:nth-child(3)::before,
.phase-bloom3 .feature:nth-child(4)::before {
  opacity: 1;
  background: linear-gradient(
    135deg,
    transparent,
    rgba(255, 170, 0, 0.1) 25%,
    rgba(229, 53, 64, 0.1) 50%,
    transparent 75%
  );
  background-size: 200% 100%;
  animation: shimmer 3s linear infinite;
}

.final-feature {
  font-style: italic;
  color: #3a2a1f;
  font-weight: 600;
}


@keyframes nodePulse {
  0% {
    transform: scale(1);
    opacity: 0.5;
  }
  100% {
    transform: scale(2);
    opacity: 0;
  }
}

@keyframes nodeHalo {
  0%, 100% {
    opacity: 0.2;
    transform: scale(1);
  }
  50% {
    opacity: 0.3;
    transform: scale(1.2);
  }
}

@keyframes nodeGlow {
  0%, 100% {
    opacity: 0.1;
    transform: scale(1);
  }
  50% {
    opacity: 0.2;
    transform: scale(1.1);
  }
}

.network-edge {
  position: absolute;
  height: 2px;
  transform-origin: left center;
  opacity: 0;
  transition: opacity 1s ease-out;
  will-change: transform, opacity;
}

.network-edge.visible {
  opacity: 1;
}

.edge-line {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform-origin: left center;
  transform: scaleX(0);
  animation: growEdge 2s cubic-bezier(0.4, 0, 0.2, 1) forwards;
  animation-delay: var(--delay);
  background: linear-gradient(
    90deg,
    transparent,
    var(--color) 20%,
    rgba(255, 255, 255, 0.9) 50%,
    var(--color) 80%,
    transparent
  );
  filter: drop-shadow(0 0 5px var(--color));
  box-shadow: 0 0 10px var(--color),
              0 0 20px var(--glow-color);
  animation: edgeGlow 3s ease-in-out infinite;
}

@keyframes growEdge {
  0% {
    transform: scaleX(0);
    opacity: 0;
  }
  100% {
    transform: scaleX(1);
    opacity: 1;
  }
}

@keyframes edgeGlow {
  0%, 100% { opacity: 0.5; filter: drop-shadow(0 0 3px var(--color)); }
  50% { opacity: 1; filter: drop-shadow(0 0 8px var(--color)); }
}

@media (max-width: 768px) {
  .intro-text {
    padding: 1.5rem;
    max-width: 85vw;
  }

  .feature-list {
    gap: clamp(0.8rem, 2vw, 1.5rem);
  }
  
  .feature {
    font-size: clamp(1.2rem, 3vw, 1.8rem);
  }
}

@media (max-width: 480px) {
  .intro-text {
    padding: 1.25rem;
    max-width: 92vw;
  }

  .feature-list {
    gap: 0.8rem;
  }
}

@media (orientation: landscape) and (max-height: 600px) {
  .intro-text {
    padding: 1rem;
    max-height: 80vh;
    overflow-y: auto;
  }

  .intro-text h1 {
    margin-bottom: 0.8rem;
  }

  .feature-list {
    gap: 0.6rem;
  }
}


@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .node-core {
    box-shadow: 0 0 10px var(--color),
                0 0 20px var(--glow-color);
  }

  .edge-line {
    box-shadow: 0 0 8px var(--color),
                0 0 15px var(--glow-color);
  }
}



@media (prefers-reduced-motion: reduce) {
  .network-node,
  .intro-text,
  .feature {
    transition: opacity 0.5s ease-out;
  }

  .node-pulse,
  .node-halo,
  .node-glow {
    animation: none;
  }

  .edge-line {
    animation-duration: 0.5s;
  }
}



.music-orb-container {
  position: fixed;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 10000;
  background: radial-gradient(
    circle at center,
    rgba(255, 170, 0, 0.05) 0%,
    rgba(229, 53, 64, 0.05) 100%
  );
  pointer-events: auto;
  user-select: none;
  touch-action: manipulation;
}

.music-orb {
  position: relative;
  width: 180px;
  height: 180px;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-tap-highlight-color: transparent;
}

.music-orb-inner {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  background: linear-gradient(135deg, #ffaa00, #e53540);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 
    0 0 60px rgba(255, 170, 0, 0.4),
    0 0 120px rgba(229, 53, 64, 0.2),
    inset 0 0 30px rgba(255, 255, 255, 0.6);
  animation: float 3s ease-in-out infinite;
  transform-origin: center;
  transition: transform 0.3s ease;
  animation: orbGlow 3s ease-in-out infinite;
}

.music-orb:hover .music-orb-inner {
  transform: scale(1.05);
}

.music-icon {
  font-size: 2.5rem;
  color: white;
  text-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  animation: musicFloat 2s ease-in-out infinite;
}

.music-orb-text {
  font-size: 0.9rem;
  color: white;
  margin-top: 0.5rem;
  font-weight: 500;
  text-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  opacity: 0.9;
}

.music-orb-rings {
  position: absolute;
  inset: -20px;
  border: 2px solid rgba(255, 170, 0, 0.2);
  border-radius: 50%;
  animation: ripple 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

.music-orb-rings::before,
.music-orb-rings::after {
  content: '';
  position: absolute;
  inset: -15px;
  border: 2px solid rgba(229, 53, 64, 0.2);
  border-radius: 50%;
  animation: ripple 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
  animation-delay: 0.5s;
}

.music-orb-rings::after {
  animation-delay: 1s;
}

@keyframes float {
  0%, 100% { transform: translateY(0); }
  50% { transform: translateY(-10px); }
}

@keyframes musicFloat {
  0%, 100% { transform: translateY(0) rotate(0); }
  50% { transform: translateY(-3px) rotate(8deg); }
}

@keyframes ripple {
  0% {
    transform: scale(0.8);
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    transform: scale(1.2);
    opacity: 0;
  }} 

@keyframes sparkle {
  0%, 100% { opacity: 0; transform: scale(0); }
  50% { opacity: 1; transform: scale(1); }
}

.node-sparkle {
  position: absolute;
  width: 4px;
  height: 4px;
  background: white;
  border-radius: 50%;
  pointer-events: none;
  animation: sparkle 1.5s ease-in-out infinite;
}

@keyframes orbGlow {
  0%, 100% { 
    box-shadow: 
      0 0 60px rgba(255, 170, 0, 0.4),
      0 0 120px rgba(229, 53, 64, 0.2),
      inset 0 0 30px rgba(255, 255, 255, 0.6);
  }
  50% { 
    box-shadow: 
      0 0 80px rgba(255, 170, 0, 0.6),
      0 0 160px rgba(229, 53, 64, 0.3),
      inset 0 0 40px rgba(255, 255, 255, 0.8);
  }
}

@keyframes featureActivate {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 170, 0, 0.4);
  }
  50% {
    transform: scale(1.02);
  }
  100% {
    transform: scale(1);
    box-shadow: 
      0 20px 40px -10px rgba(255, 170, 0, 0.2),
      inset 0 0 0 1px rgba(255, 170, 0, 0.1),
      0 0 0 4px rgba(255, 255, 255, 0.4);
  }
}
@media (hover: none) {
  .music-orb-inner {
    transition: transform 0.2s ease;
  }
  
  .music-orb:active .music-orb-inner {
    transform: scale(0.95);
  }
}

/* Enhanced Mobile Styles */
@media (max-width: 768px) {
  .intro-text {
    padding: 1.2rem;
    max-width: 92vw;
    margin: 0 1rem;
    transform: none !important;
  }

  .feature {
    font-size: 1rem;
    padding: 1rem;
    text-align: center;
    transform-origin: center;
  }

  .feature-list {
    gap: 0.8rem;
  }

  .music-orb {
    width: 140px;
    height: 140px;
  }

  .music-orb-inner {
    width: 100px;
    height: 100px;
  }

  .music-icon {
    font-size: 2rem;
  }

  .music-orb-text {
    font-size: 0.8rem;
  }

  
  .network-container {
    transform: scale(0.8);
  }

  .node-core {
    filter: brightness(1.2);
  }

  .edge-line {
    height: 1.5px;
  }
}

/* Small Mobile Devices */
@media (max-width: 480px) {
  .intro-text {
    padding: 1rem;
    margin: 0 0.5rem;
  }

  .feature {
    font-size: 0.9rem;
    padding: 0.8rem;
  }

  .feature-list {
    gap: 0.6rem;
  }

  .music-orb {
    width: 120px;
    height: 120px;
  }

  .music-orb-inner {
    width: 90px;
    height: 90px;
  }


  .network-container {
    transform: scale(0.7);
  }
}

/* Handle Landscape Mode */
@media (orientation: landscape) and (max-height: 600px) {
  .intro-text {
    max-height: 85vh;
    overflow-y: auto;
    padding: 0.8rem;
  }

  .feature-list {
    gap: 0.5rem;
  }

  .music-orb {
    transform: scale(0.8);
  }

  .network-container {
    transform: scale(0.7);
  }
}

@media (hover: none) and (pointer: coarse) {
  .music-orb-container {
    -webkit-tap-highlight-color: transparent;
  }

  .music-orb {
    cursor: pointer;
    touch-action: manipulation;
  }

  .music-orb:active .music-orb-inner {
    transform: scale(0.95);
    transition: transform 0.2s ease;
  }


  .feature {
    min-height: 44px;
    padding: 1rem;
  }

 
  .network-node,
  .edge-line,
  .feature {
    will-change: transform, opacity;
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
  }

 
  @keyframes mobileNodePulse {
    0%, 100% { opacity: 0.7; transform: scale(1); }
    50% { opacity: 0.3; transform: scale(1.3); }
  }

  .node-pulse {
    animation: mobileNodePulse 3s ease-in-out infinite;
  }
}

/* iOS Safe Areas */
@supports (padding: max(0px)) {
  .intro-text {
    padding-bottom: max(1rem, env(safe-area-inset-bottom));
    padding-left: max(1rem, env(safe-area-inset-left));
    padding-right: max(1rem, env(safe-area-inset-right));
  }

  .music-orb-container {
    padding-bottom: env(safe-area-inset-bottom);
  }
}

/* High Refresh Rate Screens */
@media (prefers-reduced-motion: no-preference) and (min-resolution: 192dpi) {
  .network-node,
  .edge-line,
  .feature {
    transition-duration: 0.3s;
  }
}

/* Progressive Enhancement for Modern Browsers */
@supports (backdrop-filter: blur(10px)) {
  .intro-text,
  .feature {
    background: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(10px);
  }
}