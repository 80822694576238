.knowledge-graph-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-color: #121212;
    overflow: hidden;
    width: 100% !important;
    height: 100% !important;
    border: none !important;
    border-radius: 0 !important;
  }
  
  .knowledge-graph-background canvas {
    opacity: 0.8;
    transition: opacity 0.5s ease-in-out;
    mix-blend-mode: screen;
  }
  
  @keyframes pulse {
    0% {
      transform: scale(1);
      opacity: 1;
    }
    50% {
      transform: scale(1.1);
      opacity: 0.7;
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
  }
  
  @keyframes floatAnimation {
    0%, 100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-5px);
    }
  }

  .knowledge-graph-background::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: radial-gradient(circle at 50% 50%, rgba(255,255,255,0.1) 0%, rgba(0,0,0,0) 70%);
    animation: pulseGlow 15s infinite ease-in-out;
    mix-blend-mode: screen;
  }
  
  .knowledge-graph-background::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(45deg, rgba(232,113,48,0.08), rgba(26,106,37,0.08), rgba(160,43,146,0.08), rgba(17,157,213,0.08));
    opacity: 0.6;
    filter: blur(80px);
    animation: gradientShift 20s infinite linear;
  }

.node-pulse {
  animation: nodePulse 1s cubic-bezier(0.4, 0, 0.6, 1) forwards;
  filter: blur(2px);
}

@keyframes nodePulse {
  0% {
    transform: scale(1);
    filter: brightness(1);
  }
  50% {
    transform: scale(1.5);
    filter: brightness(1.5) blur(4px);
  }
  100% {
    transform: scale(1);
    filter: brightness(1);
  }
}

.chord-glow {
  animation: chordGlow 2s ease-in-out infinite;
}

@keyframes chordGlow {
  0%, 100% {
    filter: brightness(1) blur(2px);
  }
  50% {
    filter: brightness(1.8) blur(6px);
  }
}

.cursor-attraction {
  transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

@keyframes pulseGlow {
  0%, 100% {
    opacity: 0.3;
    transform: scale(1);
  }
  50% {
    opacity: 0.5;
    transform: scale(1.1);
  }
}

@keyframes gradientShift {
  0% {
    background-position: 0% 0%;
  }
  100% {
    background-position: 200% 200%;
  }
}

@media (max-width: 600px) {
  .knowledge-graph-background::after {
    animation: pulse 6s infinite ease-in-out;
  }
  
  .knowledge-graph-background::before {
    filter: blur(30px);
  }

  .node-pulse {
    animation-duration: 0.8s;
  }

  .cursor-attraction {
    transition: transform 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  }

  /* Add touch feedback */
  .touch-active {
    transform: scale(1.2);
    filter: brightness(1.3);
    transition: all 0.2s ease-out;
  }
}

/* Add touch-specific animations */
@keyframes touchPulse {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.3);
    opacity: 0.8;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.touch-feedback {
  animation: touchPulse 0.3s ease-out;
}


